import {
  TTechClaimIntakeClient,
  CreateWcClaimCommand,
  CreateWcClaimCommandInput,
} from "@amzn/ttechclaimintakeservice-client";
import {
  CLIENT_ERROR_RESPONSES,
  HttpStatusCodes,
  ProcessedApiResponse,
  SERVER_ERROR_RESPONSES,
} from "./../types";
import { setupClaimIntakeClient } from "../setup/setupClaimIntakeClient";
import { getAuthenticationToken } from "../../auth/helpers";
import { MetricsPublisher } from "../../metrics/metrics";
import {
  ANCHOR,
  ASSOCIATE_INFORMATION_CONSTANTS,
  HELMSMAN,
  INCIDENT_TIME_AND_PLACE_CONSTANTS,
  SEDGWICK,
} from "../../forms/constants";
import moment from "moment-timezone";

export async function SendCreateWcClaimCommand(
  input: CreateWcClaimCommandInput
) {
  const token = await getAuthenticationToken();
  if (!token) {
    const responseStruct: ProcessedApiResponse = {
      statusCode: HttpStatusCodes.UNAUTHORIZED,
      data: [],
      warningList: [],
    };
    return responseStruct;
  }
  const client: TTechClaimIntakeClient = setupClaimIntakeClient(token);
  const command = new CreateWcClaimCommand(input);
  const metricsPublisher = new MetricsPublisher("api.claimIntake");
  const metricsErrorCodePublisher = new MetricsPublisher(
    "claimSubmittedErrorCode"
  );
  const metricsSiteCodePublisher = new MetricsPublisher(
    "claimSubmittedBySiteName"
  );
  const metricsSiteCodePublisherAnchor = new MetricsPublisher(
    "claimSubmittedAnchorSite"
  );
  const metricsSiteCodePublisherSedgwick = new MetricsPublisher(
    "claimSubmittedSedgwickSite"
  );
  const metricsSiteCodePublisherHelmsman = new MetricsPublisher(
    "claimSubmittedHelmsmanSite"
  );

  return await client
    .send(command)
    .then((response: any) => {
      const statusCode = response?.$metadata?.httpStatusCode?.toString();
      const processedResponse: ProcessedApiResponse = {
        statusCode,
        warningList: response.warningList,
        data: [
          {
            fieldName: "Associate Employee ID",
            fieldValue: response.associateEmployeeId,
          },
          {
            fieldName: "Created At",
            fieldValue: moment(response.createdAtTimestamp).format(
              "MMMM Do YYYY, h:mm:ss a"
            ),
          },
          {
            fieldName: "Claim Number",
            fieldValue: response.claimNumber,
          },
        ],
      };

      if (
        input[INCIDENT_TIME_AND_PLACE_CONSTANTS.INCIDENT_SITE_NAME] !==
        input[ASSOCIATE_INFORMATION_CONSTANTS.ASSOCIATE_HOME_SITE_CODE]
      ) {
        metricsPublisher.publishIncidentSiteDifferentThanHome();
      }
      //Overall Metrics
      metricsPublisher.publishSubmitClaimLatencyMetrics();
      metricsPublisher.publishClaimSubmittedSuccessMetrics();
      metricsSiteCodePublisher.publishSiteOfSubmittedClaim(
        input.incidentSiteName
      );

      //TPA specific metrics
      if (input.thirdPartyAdjuster === SEDGWICK) {
        metricsPublisher.publishClaimSubmittedSuccessSedgwickMetrics();
        metricsSiteCodePublisherSedgwick.publishSiteOfSubmittedClaim(
          input.incidentSiteName
        );
      } else if (input.thirdPartyAdjuster === ANCHOR) {
        metricsPublisher.publishClaimSubmittedSuccessAnchorMetrics();
        metricsSiteCodePublisherAnchor.publishSiteOfSubmittedClaim(
          input.incidentSiteName
        );
      } else if (input.thirdPartyAdjuster === HELMSMAN) {
        metricsPublisher.publishClaimSubmittedSuccessHelmsmanMetrics();
        metricsSiteCodePublisherHelmsman.publishSiteOfSubmittedClaim(
          input.incidentSiteName
        );
      }

      return processedResponse;
    })
    .catch((error: any) => {
      const statusCode =
        error?.$metadata?.httpStatusCode?.toString() || "Unknown";
      metricsPublisher.publishClaimSubmittedFailure();
      metricsPublisher.publishSubmitClaimLatencyMetrics();
      metricsErrorCodePublisher.publishClaimSubmittedErrorCodeMetrics(
        statusCode
      );
      // Metrics for 4XX error status codes
      if (CLIENT_ERROR_RESPONSES.includes(statusCode)) {
        metricsPublisher.publishClaimSubmittedClientErrorMetrics();
        if (statusCode === HttpStatusCodes.BAD_REQUEST) {
          metricsPublisher.claimValidationError();
        }
      }

      // Metrics for 5XX error status codes
      if (SERVER_ERROR_RESPONSES.includes(statusCode)) {
        metricsPublisher.publishClaimSubmittedServerFailureMetrics();
        if (statusCode === HttpStatusCodes.GATEWAY_TIMEOUT) {
          metricsPublisher.publishClaimSubmittedTimeoutMetrics();
        }
      }

      throw error;
    });
}
