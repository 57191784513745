import React, { useRef } from "react";
import { useState, useEffect } from "react";

import {
  SpaceBetween,
  Header,
  Container,
  Box,
  Icon,
  FormField,
  Select,
  SelectProps,
  Button,
  Grid,
  Link,
  Alert,
} from "@amzn/awsui-components-react/polaris";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import type { RootState } from "../../redux/store";
import moment from "moment";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import SimLinkGenerator from "src/components/simLinkGenerator/SimLinkGenerator";
import { SIM_TYPES } from "src/components/simLinkGenerator/types";
import {
  getWcFile,
  listWcFiles,
  setValueInWorkersCompensationDocumentsState,
} from "../workersCompensationDocuments/workersCompensationDocumentsSlice";
import { STATE_FORM_DOCUMENT_TYPE } from "src/views/workersCompensationDocuments/constants";
import { WcFile } from "@amzn/ttechclaimintakeservice-client";
import RetrievingFileList from "../workersCompensationDocuments/RetrievingFileList";
import { SiteTypes } from "src/sites/constants";
import { FileOption } from "./types";

enum FILE_ORIGIN {
  MATCHING_SITE = "matchingSite",
  SELECTED_SITE = "selectedSite",
}

const Stateforms = () => {
  /**
   * Setup Redux variables and get state
   */

  //Redux WorkersCompensationDocuments state
  const {
    files,
    fileUrl,
    gettingFiles,
    gettingFileUrl,
    listFilesError,
    getFileUrlError,
  } = useAppSelector(
    (state: RootState) =>
      state.workersCompensationDocuments[STATE_FORM_DOCUMENT_TYPE]
  );
  //Redux Site state
  const site = useAppSelector(
    (state: RootState) => state.sites[SiteTypes.DOCUMENTS_PAGE_SITE]
  );

  const dispatch = useAppDispatch();
  const [fileOptions, setFileOptions] = useState<FileOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<FileOption | null>(null);
  const [matchingFile, setMatchingFile] = useState<WcFile | null>(null);
  const [alert, setAlert] = useState<React.ReactNode | null>(null);
  const [fileUrlOrigin, setFileUrlOrigin] = useState<FILE_ORIGIN | null>(null);

  const siteLoaded = useRef(false);
  /**
   * Get file list on load
   */
  useEffect(() => {
    dispatch(listWcFiles({ fileType: STATE_FORM_DOCUMENT_TYPE }));
  }, []);

  useEffect(() => {
    if (site?.state && !siteLoaded.current) {
      siteLoaded.current = true;
    }
  }, [site?.state]);
  /**
   * Search files for matching site
   */
  useEffect(() => {
    const filtered = site?.state
      ? files.filter((file: WcFile) => {
          // Splitting fileName to isolate site code and avoid getting multiple matches
          const splitFileName = file.fileName?.split(/[-._\s/:,#]+/);
          return splitFileName?.includes(site.state!);
        })
      : [];

    const options = files.reduce((result: FileOption[], file: WcFile) => {
      const tokenizedFileName = file?.fileName?.split("/") ?? [];
      const standaloneFileName =
        tokenizedFileName[tokenizedFileName?.length - 1];
      if (!standaloneFileName || !file.fileName) return result;
      result.push({
        value: file.fileName,
        label: standaloneFileName,
        lastUploadTimestamp: file.lastUploadTimestamp,
      });
      return result;
    }, []);
    /**
     * Sort options alphabetically based on label.
     * File names sorting is being handled in the Front-end and not in the Back-end
     * because file names are already being processed here and it will keep the
     * data processing step consistent.
     */
    options.sort((a: FileOption, b: FileOption) => {
      return a.label.localeCompare(b.label);
    });
    setFileOptions(options);
    if (filtered?.length) {
      setMatchingFile(filtered[0]);
    }
  }, [files, site?.state]);

  /**
   * Trigger file download when url is received
   */
  useEffect(() => {
    if (fileUrl) {
      window.open(fileUrl, "_blank");
      dispatch(
        setValueInWorkersCompensationDocumentsState({
          key: "fileUrl",
          value: "",
          fileType: STATE_FORM_DOCUMENT_TYPE,
        })
      );
    }
  }, [fileUrl]);

  /**
   * Set alert if there was an error getting the fileUrl
   */
  useEffect(() => {
    if (getFileUrlError) {
      setAlert(
        <Box data-testid="getFileUrlErrorAlert">
          There was a problem retrieving the download link for this file, please
          try again later, reload the page or search the documents below for
          another file, if the problem persists please open a{" "}
          <SimLinkGenerator
            simType={SIM_TYPES.REPORT_A_BUG}
            target="_blank"
            ariaLabel="Pressing this link will open a new tab where you will be able to create a ticket for the team to investigate this issue."
          >
            support ticket
          </SimLinkGenerator>{" "}
          to assist you with this issue.
        </Box>
      );
    } else {
      setAlert(null);
    }
  }, [getFileUrlError]);

  const getFileUrl = (fileName?: string) => {
    if (!fileName) return;
    setAlert(null);
    dispatch(getWcFile({ fileName, fileType: STATE_FORM_DOCUMENT_TYPE }));
  };
  const renderMatchFoundMessage = () => {
    let result = <></>;
    if (matchingFile) {
      const tokenizedFileName = matchingFile.fileName?.split("/") ?? [];
      const standaloneFileName =
        tokenizedFileName[tokenizedFileName.length - 1];
      result = (
        <SpaceBetween size="xs" direction="vertical">
          <Box
            variant="p"
            color="text-status-success"
            data-testid="matchingFileSuccess"
          >
            <Icon name="status-positive"></Icon> Click on the download link
            below to get your state form or search for other state forms in the
            dropdown menu.
          </Box>
          <Box variant="p">
            <b>File: </b>
            <span
              data-testid="matchingFileDownloadLink"
              onClick={() => {
                setFileUrlOrigin(FILE_ORIGIN.MATCHING_SITE);
                getFileUrl(matchingFile.fileName);
              }}
            >
              <Link variant="primary">
                {`${standaloneFileName} `} <Icon name="download"></Icon>
              </Link>
            </span>
            {gettingFileUrl && fileUrlOrigin === FILE_ORIGIN.MATCHING_SITE ? (
              <Spinner />
            ) : null}
            <br />
            <span>
              <b>Date Uploaded: </b>
              {`${moment(matchingFile.lastUploadTimestamp).format("LLLL")}`}
            </span>
          </Box>
        </SpaceBetween>
      );
    }
    return result;
  };

  const renderErrorListingFiles = () => {
    return (
      <SpaceBetween
        direction="vertical"
        size="xs"
        data-testid="listingFilesErrorAlertContainer"
      >
        <Alert type="error" data-testid="listingFilesErrorAlert">
          There was an error retrieving the files. Please reload the page or
          click on the button below to try again. If the error persists please
          open a{" "}
          <SimLinkGenerator
            simType={SIM_TYPES.REPORT_A_BUG}
            target="_blank"
            ariaLabel="Pressing this link will open a new tab where you will be able to create a ticket for the team to investigate this issue."
          >
            support ticket
          </SimLinkGenerator>{" "}
          to get help with this issue.
        </Alert>
        <Button
          variant="normal"
          onClick={() => {
            dispatch(listWcFiles({ fileType: STATE_FORM_DOCUMENT_TYPE }));
          }}
        >
          Reload File List
        </Button>
      </SpaceBetween>
    );
  };

  const renderErrorAlert = () => {
    if (alert) {
      return (
        <Alert type="error" data-testid="stateFormsErrorAlert">
          {alert}
        </Alert>
      );
    }
  };

  return (
    <SpaceBetween direction="vertical" size="s">
      <Container
        header={
          <Header variant="h2">Workers&apos; Compensation State Forms</Header>
        }
      >
        <SpaceBetween direction="vertical" size="s">
          {listFilesError ? (
            renderErrorListingFiles()
          ) : gettingFiles ? (
            <RetrievingFileList />
          ) : (
            <SpaceBetween size="s" direction="vertical">
              {renderErrorAlert()}
              {renderMatchFoundMessage()}

              <FormField
                description="Search from the dropdown menu for the currently available Workers' Compensation State Forms"
                data-testid="workersCompensationStateFormsFormField"
              >
                <Grid gridDefinition={[{ colspan: 8 }, { colspan: 4 }]}>
                  <Select
                    options={fileOptions}
                    selectedOption={selectedOption}
                    onChange={({
                      detail,
                    }: {
                      detail: SelectProps.ChangeDetail;
                    }) => {
                      setSelectedOption(detail.selectedOption as FileOption);
                    }}
                    filteringType="auto"
                    placeholder="Select a file to download"
                    virtualScroll={true}
                  ></Select>
                  <Button
                    disabled={!selectedOption || gettingFileUrl}
                    variant="primary"
                    onClick={() => {
                      setFileUrlOrigin(FILE_ORIGIN.SELECTED_SITE);
                      getFileUrl(selectedOption?.value);
                    }}
                    loading={
                      gettingFileUrl &&
                      fileUrlOrigin === FILE_ORIGIN.SELECTED_SITE
                    }
                  >
                    Download
                  </Button>
                </Grid>
                {selectedOption ? (
                  <div>
                    <span>
                      <b>Date Uploaded: </b>
                      {`${moment(selectedOption.lastUploadTimestamp).format(
                        "LLLL"
                      )}`}
                      {}
                    </span>
                  </div>
                ) : null}
              </FormField>
            </SpaceBetween>
          )}
        </SpaceBetween>
      </Container>
    </SpaceBetween>
  );
};
export default Stateforms;
