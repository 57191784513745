import {
  AttachmentInput,
  CreateWcClaimCommandInput,
  PhysicianInformationInput,
  SiteDetailsInput,
  WitnessInformationInput,
} from "@amzn/ttechclaimintakeservice-client";
import moment from "moment-timezone";
import {
  FormFieldConfigType,
  FormFieldStateType,
  FormStateType,
  INPUT_TYPES,
  SelectOptionType,
} from "src/forms/types";
import { store } from "src/redux/store";
import {
  ALL_FILE_UPLOAD_FIELDS,
  ASSOCIATE_INFORMATION_CONSTANTS,
  INCIDENT_INFORMATION_CONSTANTS,
  INCIDENT_TIME_AND_PLACE_CONSTANTS,
  PHYSICIAN_INFORMATION_CONSTANTS,
  SEDGWICK,
  SITE_CONFIGURATION_CONSTANTS,
} from "src/forms/constants";
import {
  CreateWcClaimModelAdditionalFields,
  CreateWcClaimProcessingModel,
} from "./types";
import { stringToBoolean } from "src/forms/tools";
import {
  NON_COMPANY_LOCATION,
  WORKING_FROM_HOME_EMPLOYEES_ADDRESS,
} from "src/forms/options";
import { S3StagedFile } from "src/forms/FileUpload/types";

/**
 * Builds a Workers Compensation claim intake model from form state and additional fields
 * @param formState - The current state of the form
 * @param formConfig - Configuration array defining form field structure
 * @param additionalFields - Additional fields required for claim creation including incidentSite,
 * associateHomeSite, user, workersCompensationTpa and amazonClaimReferenceId
 * @returns CreateWcClaimCommandInput - Processed claim intake model ready for submission
 */
export function buildWcCreateClaimInput(
  formState: FormStateType,
  formConfig: FormFieldConfigType[],
  additionalFields: CreateWcClaimModelAdditionalFields
): CreateWcClaimCommandInput {
  const tempInputModel: CreateWcClaimCommandInput = {};

  processFields(formState, formConfig, tempInputModel);
  const createClaimInputModel: CreateWcClaimCommandInput = {
    ...postProcessModel(tempInputModel, additionalFields),
  };
  return createClaimInputModel;
}
/**
 * Determines if a field should be skipped during form processing
 * @param fieldConfig - Configuration for the form field
 * @param fieldState - Current state of the form field
 * @returns boolean - True if field should be skipped, false otherwise
 */
const shouldFieldBeSkipped = (
  fieldConfig: FormFieldConfigType,
  fieldState: FormFieldStateType
) => {
  const hiddenFieldsIncluded = [
    ASSOCIATE_INFORMATION_CONSTANTS.WORKER_SUB_TYPE,
    ASSOCIATE_INFORMATION_CONSTANTS.SUPERVISOR_ALIAS,
    ASSOCIATE_INFORMATION_CONSTANTS.SUPERVISOR_NAME,
    INCIDENT_TIME_AND_PLACE_CONSTANTS.LOCATION_TYPE,
  ];
  return (
    !fieldConfig ||
    !fieldConfig?.name ||
    fieldState?.ignoreInFinalForm ||
    (fieldState?.hidden &&
      !hiddenFieldsIncluded.includes(
        fieldConfig.name as ASSOCIATE_INFORMATION_CONSTANTS
      ))
  );
};

/**
 * Handles special processing cases for specific form fields
 * @param fieldName - Name of the field being processed
 * @param fieldState - Current state of the field
 * @param model - The claim intake model being built
 * @returns boolean - True if special case was handled, false otherwise
 */
const handleSpecialCases = (
  fieldName: string,
  fieldState: FormFieldStateType,
  model: CreateWcClaimProcessingModel
) => {
  /**
   * Handle specific cases for individual fields.
   */
  if (ALL_FILE_UPLOAD_FIELDS.includes(fieldName)) {
    model[fieldName as keyof CreateWcClaimProcessingModel] = buildAttachments(
      fieldState.value
    );
    return true;
  }
  switch (fieldName) {
    case INCIDENT_INFORMATION_CONSTANTS.NATURE_OF_INJURY:
      model[INCIDENT_INFORMATION_CONSTANTS.NATURE_OF_INJURY] =
        fieldState.value?.value;
      model[INCIDENT_INFORMATION_CONSTANTS.NATURE_OF_INJURY_DESCRIPTION] =
        fieldState.value?.label;
      return true;

    case INCIDENT_INFORMATION_CONSTANTS.CAUSE_OF_INJURY:
      model[INCIDENT_INFORMATION_CONSTANTS.CAUSE_OF_INJURY] =
        fieldState.value?.value;
      model[INCIDENT_INFORMATION_CONSTANTS.CAUSE_OF_INJURY_DESCRIPTION] =
        fieldState.value?.label;
      return true;

    case INCIDENT_INFORMATION_CONSTANTS.SECONDARY_NATURE_OF_INJURY:
      model[INCIDENT_INFORMATION_CONSTANTS.SECONDARY_NATURE_OF_INJURY] =
        fieldState.value?.value;
      model[
        INCIDENT_INFORMATION_CONSTANTS.SECONDARY_NATURE_OF_INJURY_DESCRIPTION
      ] = fieldState.value?.label;
      return true;

    case INCIDENT_INFORMATION_CONSTANTS.SYMPTOMS_LIST:
      model[INCIDENT_INFORMATION_CONSTANTS.SYMPTOMS_LIST] =
        fieldState.value?.map((entry: SelectOptionType) => {
          return entry.value;
        });
      return true;

    case INCIDENT_INFORMATION_CONSTANTS.ACCIDENT_TYPE:
      model[INCIDENT_INFORMATION_CONSTANTS.ACCIDENT_TYPE] =
        fieldState.value?.value;
      model[INCIDENT_INFORMATION_CONSTANTS.ACCIDENT_TYPE_DESCRIPTION] =
        fieldState.value?.label;
      return true;

    default:
      return false;
  }
};

/**
 * Processes body part related fields in the form
 * @param fieldConfig - Configuration for the body part field group
 * @param formState - Current state of the entire form
 * @param model - The claim intake model being built
 * @returns boolean - True if body part fields were processed, false otherwise
 */
const handleBodyPartFields = (
  fieldConfig: FormFieldConfigType,
  formState: FormStateType,
  model: CreateWcClaimProcessingModel
) => {
  if (fieldConfig.name.includes("bodyPartInformation") && fieldConfig.fields) {
    const injuredBodyPartFieldName = fieldConfig.fields[0].name;
    const injuredSideFieldName = fieldConfig.fields[1].name;
    const natureOfInjuryFieldName = fieldConfig.fields[2].name;

    const injuredBodyPart = formState[injuredBodyPartFieldName];
    const injuredSide = formState[injuredSideFieldName];
    const natureOfInjury = formState[natureOfInjuryFieldName];

    if (injuredBodyPart && injuredBodyPart.value?.value) {
      model[injuredBodyPartFieldName] = injuredBodyPart.value?.value;
      model[`${injuredBodyPartFieldName}Description`] =
        injuredBodyPart.value?.label;
    }
    if (injuredSide && injuredSide.value?.value) {
      model[injuredSideFieldName] = injuredSide.value?.value;
    }
    if (natureOfInjury && natureOfInjury.value?.value) {
      model[natureOfInjuryFieldName] = natureOfInjury.value?.value;
      model[`${natureOfInjuryFieldName}Description`] =
        natureOfInjury.value?.label;
    }
    return true;
  }
  return false;
};

/**
 * Processes all fields in the form configuration
 * @param formState - Current state of the form
 * @param formConfig - Configuration array defining form field structure
 * @param model - The claim intake model being built
 */
const processFields = (
  formState: FormStateType,
  formConfig: FormFieldConfigType[],
  model: CreateWcClaimProcessingModel
) => {
  for (const fieldConfig of formConfig) {
    const fieldState = formState[fieldConfig.uniqueName || fieldConfig.name];
    if (shouldFieldBeSkipped(fieldConfig, fieldState)) {
      continue;
    }
    const fieldName = fieldConfig.name.split("_")[0];
    if (fieldConfig.type === INPUT_TYPES.FIELD_GROUP) {
      if (handleBodyPartFields(fieldConfig, formState, model)) {
        continue;
      }
      model[fieldConfig.name] = {};
      processFields(formState, fieldConfig.fields, model[fieldConfig.name]);
    } else {
      if (handleSpecialCases(fieldName, fieldState, model)) {
        continue;
      }
      const value =
        fieldState?.value?.value || stringToBoolean(fieldState.value);
      model[fieldConfig.name] = value;
    }
  }
};

/**
 * Post-processes site related information in the claim model
 * @param model - The claim intake model being built
 * @param additionalFields - Additional fields containing site information
 */
const postProcessSiteInformation = (
  model: CreateWcClaimProcessingModel,
  additionalFields: CreateWcClaimModelAdditionalFields
) => {
  const sitesState = store.getState().sites;
  model[ASSOCIATE_INFORMATION_CONSTANTS.ASSOCIATE_HOME_SITE_UNIT_NUMBER] =
    sitesState.associateHomeSite?.unitNumber || "";

  // Check if incident site and home site should be the same
  const shouldIncidentSiteEqualHomeSite =
    model[INCIDENT_TIME_AND_PLACE_CONSTANTS.HOME_SITE_EQUAL_TO_INCIDENT_SITE] ||
    model[INCIDENT_TIME_AND_PLACE_CONSTANTS.LOCATION_TYPE] ===
      WORKING_FROM_HOME_EMPLOYEES_ADDRESS.value ||
    model[INCIDENT_TIME_AND_PLACE_CONSTANTS.LOCATION_TYPE] ===
      NON_COMPANY_LOCATION.value;

  // Set incident site information
  model[INCIDENT_TIME_AND_PLACE_CONSTANTS.INCIDENT_SITE_NAME] =
    shouldIncidentSiteEqualHomeSite
      ? model[ASSOCIATE_INFORMATION_CONSTANTS.ASSOCIATE_HOME_SITE_CODE]
      : model[INCIDENT_TIME_AND_PLACE_CONSTANTS.INCIDENT_SITE_NAME];
  model[INCIDENT_TIME_AND_PLACE_CONSTANTS.INCIDENT_SITE_UNIT_NUMBER] =
    shouldIncidentSiteEqualHomeSite
      ? model[ASSOCIATE_INFORMATION_CONSTANTS.ASSOCIATE_HOME_SITE_UNIT_NUMBER]
      : additionalFields.incidentSite?.unitNumber || "";
  model[INCIDENT_TIME_AND_PLACE_CONSTANTS.INCIDENT_SITE_DETAILS] =
    shouldIncidentSiteEqualHomeSite
      ? (additionalFields.associateHomeSite as SiteDetailsInput)
      : (additionalFields.incidentSite as SiteDetailsInput);

  // Set associate home site information
  model[ASSOCIATE_INFORMATION_CONSTANTS.ASSOCIATE_HOME_SITE_DETAILS] =
    additionalFields.associateHomeSite as SiteDetailsInput;

  // Set notification fields
  model[SITE_CONFIGURATION_CONSTANTS.NOTIFICATION_ALIAS_LIST] =
    additionalFields?.associateHomeSite?.notificationAliasList;
  model[SITE_CONFIGURATION_CONSTANTS.NOTIFICATION_WEBHOOK] =
    additionalFields?.associateHomeSite?.notificationWebhook;

  // Set benefit state
  model[SITE_CONFIGURATION_CONSTANTS.BENEFIT_STATE] =
    model.incidentSiteDetails?.state || "";
};

/**
 * Post-processes medical information including physician and witness details
 * @param model - The claim intake model being modified
 */
const postProcessMedicalInformation = (model: CreateWcClaimProcessingModel) => {
  const physicianInformationList: PhysicianInformationInput[] = [];
  const witnessInformationList: WitnessInformationInput[] = [];

  if (model[PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_0]) {
    physicianInformationList.push(
      model[PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_0]
    );
  }
  if (model[PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_1]) {
    physicianInformationList.push(
      model[PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_1]
    );
  }

  if (model[INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_0]) {
    witnessInformationList.push({
      ...model[INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_0],
    });
  }
  if (model[INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_1]) {
    witnessInformationList.push({
      ...model[INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_1],
    });
  }
  model[PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_LIST] =
    physicianInformationList;
  model[INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_LIST] =
    witnessInformationList;

  delete model[INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_0];
  delete model[INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_1];
  delete model[INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_0];
  delete model[INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_1];
};

/**
 * Post-processes questionable claim reasons and additional information
 * @param model - The claim intake model being modified
 */
const postProcessQuestionableClaimReasons = (
  model: CreateWcClaimProcessingModel
) => {
  // Check if user selected there are reasons to consider this a questionable claim,
  // remove from model and return if not
  if (!model[INCIDENT_INFORMATION_CONSTANTS.QUESTIONABLE_CLAIM]) {
    delete model[INCIDENT_INFORMATION_CONSTANTS.QUESTIONABLE_CLAIM_REASONS];
    delete model[
      INCIDENT_INFORMATION_CONSTANTS.QUESTIONABLE_CLAIM_ADDITIONAL_REASON
    ];
    return;
  }

  // Process questionable claim reasons array and additional reasons
  let questionableClaimReasons: string[] = [];
  let questionableClaimAdditionalReason = "";
  if (model[INCIDENT_INFORMATION_CONSTANTS.QUESTIONABLE_CLAIM_GROUP]) {
    questionableClaimReasons = model[
      INCIDENT_INFORMATION_CONSTANTS.QUESTIONABLE_CLAIM_GROUP
    ][INCIDENT_INFORMATION_CONSTANTS.QUESTIONABLE_CLAIM_REASONS].map(
      (entry: SelectOptionType) => {
        return entry.value;
      }
    );
    questionableClaimAdditionalReason =
      model[INCIDENT_INFORMATION_CONSTANTS.QUESTIONABLE_CLAIM_GROUP][
        INCIDENT_INFORMATION_CONSTANTS.QUESTIONABLE_CLAIM_ADDITIONAL_REASON
      ];
  }
  model[INCIDENT_INFORMATION_CONSTANTS.QUESTIONABLE_CLAIM_REASONS] =
    questionableClaimReasons;
  model[INCIDENT_INFORMATION_CONSTANTS.QUESTIONABLE_CLAIM_ADDITIONAL_REASON] =
    questionableClaimAdditionalReason;
};

/**
 * Performs final processing on the claim model before submission
 * @param model - The initial claim intake model
 * @param additionalFields - Additional fields required for claim intake model processing
 * @returns CreateWcClaimProcessingModel - The fully processed claim model
 */
const postProcessModel = (
  model: CreateWcClaimProcessingModel,
  additionalFields: CreateWcClaimModelAdditionalFields
) => {
  let newModel: CreateWcClaimProcessingModel = {};
  //Remove top level groupFields
  for (const tempFields of Object.values(model)) {
    newModel = { ...newModel, ...tempFields };
  }

  //Handle individual field requirements
  postProcessSiteInformation(newModel, additionalFields);
  postProcessMedicalInformation(newModel);
  postProcessQuestionableClaimReasons(newModel);

  //Add all fields to model
  newModel = {
    ...newModel,
    incidentDate: newModel.incidentDate?.replace(/-/g, "/"),
    employerNotifiedDate: newModel.employerNotifiedDate?.replace(/-/g, "/"),
    timezone: moment.tz.guess(),
    draft: false,
    testRecord: false,
    thirdPartyAdjuster: additionalFields?.workersCompensationTpa || SEDGWICK,
    claimReporterFirstName: additionalFields?.user?.givenName || "",
    claimReporterLastName: additionalFields?.user?.familyName || "",
    claimReporterAlias: additionalFields?.user?.id || "",
    claimReporterJobDescription: additionalFields?.user?.jobDescription || "",
    amazonClaimReferenceId: additionalFields?.amazonClaimReferenceId || "",
  };

  if (!newModel.questionableClaim) {
    delete newModel.questionableClaimReasons;
    delete newModel.questionableClaimAdditionalReason;
  }
  return newModel;
};

/**
 * Builds attachment input array from staged files
 * @param value - Array of staged files from S3
 * @returns AttachmentInput[] - Array of processed file attachments
 */
const buildAttachments = (value: S3StagedFile[]): AttachmentInput[] => {
  const fileAttachments: AttachmentInput[] = [];

  value.forEach((file) => {
    const attachment = {
      fileExtension: file.name.split(".").pop(),
      s3Key: file.s3Key,
      fileName: file.name,
      fileSize: file.size,
    };

    fileAttachments.push(attachment);
  });

  return fileAttachments;
};
