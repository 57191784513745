import React, { useEffect, useState } from "react";

import {
  Alert,
  Container,
  Header,
  SpaceBetween,
  Button,
  FormField,
  Link,
  Box,
} from "@amzn/awsui-components-react/polaris";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { RootState } from "src/redux/store";
import { AUTHORIZATION_FORM_DOCUMENT_TYPE } from "src/views/workersCompensationDocuments/constants";
import {
  getWcFile,
  listWcFiles,
  setValueInWorkersCompensationDocumentsState,
} from "src/views/workersCompensationDocuments/workersCompensationDocumentsSlice";
import { SiteTypes } from "src/sites/constants";
import { getSite } from "src/sites/sitesSlice";
import {
  ANCHOR,
  ANCHOR_STATES,
  HELMSMAN,
  HELMSMAN_STATES,
  SEDGWICK,
} from "src/forms/constants";
import SimLinkGenerator from "src/components/simLinkGenerator/SimLinkGenerator";
import { SIM_TYPES } from "src/components/simLinkGenerator/types";
import RetrievingFileList from "./RetrievingFileList";
import { WcFile } from "@amzn/ttechclaimintakeservice-client";

const MedicalAuthorizationDocuments = () => {
  const [alert, setAlert] = useState<React.ReactNode | null>(null);

  const dispatch = useAppDispatch();

  // Redux MedicalAuthorizationDocuments state
  const { files, gettingFiles, getFileUrlError, fileUrl } = useAppSelector(
    (state: RootState) =>
      state.workersCompensationDocuments[AUTHORIZATION_FORM_DOCUMENT_TYPE]
  );
  //Redux Auth state
  const { user } = useAppSelector((state: RootState) => state.auth);

  //Redux Site state
  const site = useAppSelector(
    (state: RootState) => state.sites[SiteTypes.DOCUMENTS_PAGE_SITE]
  );
  /**
   * Get file list on load
   */
  useEffect(() => {
    dispatch(listWcFiles({ fileType: AUTHORIZATION_FORM_DOCUMENT_TYPE }));
    dispatch(
      getSite({ code: user.siteCode, type: SiteTypes.DOCUMENTS_PAGE_SITE })
    );
  }, []);

  /**
   * Trigger file download when url is received
   */
  useEffect(() => {
    if (fileUrl) {
      window.open(fileUrl, "_blank");
      dispatch(
        setValueInWorkersCompensationDocumentsState({
          key: "fileUrl",
          value: "",
          fileType: AUTHORIZATION_FORM_DOCUMENT_TYPE,
        })
      );
    }
  }, [fileUrl]);

  const getFileUrl = (fileName?: string) => {
    if (!fileName) return;
    setAlert(null);
    dispatch(
      getWcFile({ fileName, fileType: AUTHORIZATION_FORM_DOCUMENT_TYPE })
    );
  };

  /**
   * Set alert if there was an error getting the fileUrl
   */
  useEffect(() => {
    if (getFileUrlError) {
      setAlert(
        <Box data-testid="getFileUrlErrorAlert">
          There was a problem retrieving the download link for this file, please
          try again later, reload the page or search the documents below for
          another file, if the problem persists please open a{" "}
          <SimLinkGenerator
            simType={SIM_TYPES.REPORT_A_BUG}
            target="_blank"
            ariaLabel="Pressing this link will open a new tab where you will be able to create a ticket for the team to investigate this issue."
          >
            support ticket
          </SimLinkGenerator>{" "}
          to assist you with this issue.
        </Box>
      );
    } else {
      setAlert(null);
    }
  }, [getFileUrlError]);

  const renderTpaAlert = () => {
    if (HELMSMAN_STATES.includes(site?.state ?? "")) {
      return (
        <Alert header="TPA Change Warning" type="warning">
          Your state was identified as one of the states that switched the
          Workers&apos; Compensation Third Party Administrator from Sedgwick to
          Helmsman, as such, incidents that happened on or after 10/1/2024 will
          require the Helmsman Authorization form and incidents that happened
          before 10/1/2024 will require the Sedgwick Authorization form.
        </Alert>
      );
    }
  };
  const renderErrorAlert = () => {
    if (alert) {
      return (
        <Alert type="error" data-testid="medicalPanelDataAlert">
          {alert}
        </Alert>
      );
    }
  };

  const renderDownloads = () => {
    const helmsmanFiles = files.filter((file) =>
      file.fileName?.toLowerCase().includes(HELMSMAN.toLowerCase())
    );
    const sedgwickFiles = files.filter((file) =>
      file.fileName?.toLowerCase().includes(SEDGWICK.toLowerCase())
    );
    const anchorFiles = files.filter((file) =>
      file.fileName?.toLowerCase().includes(ANCHOR.toLowerCase())
    );
    let filesToShow: WcFile[] = [];
    let filesAlert: React.ReactElement | null = null;
    if (!site?.code) {
      filesToShow = [...helmsmanFiles, ...sedgwickFiles, ...anchorFiles];
    } else {
      if (HELMSMAN_STATES.includes(site?.state ?? "")) {
        filesToShow = [...helmsmanFiles, ...sedgwickFiles];
      } else if (ANCHOR_STATES.includes(site?.state ?? "")) {
        filesToShow = [...anchorFiles];
      } else {
        filesToShow = [...sedgwickFiles];
      }
    }
    if (filesToShow.length === 0) {
      filesAlert = (
        <Alert type="warning">
          There were no Authorization for Initial Medical Evaluation forms found
          for your site, please submit a{" "}
          <Link
            href="https://t.corp.amazon.com/create/templates/b3c14171-612e-492e-9489-b04c4b0297a3"
            target="_blank"
            variant="primary"
            ariaLabel="Pressing this link will open a new tab where you will be able to create a ticket to help you with this issue."
          >
            ticket{" "}
          </Link>
          if you need assistance with getting an authorization form for your
          site.
        </Alert>
      );
    }
    const downloadsToReturn = filesToShow.map((file) => {
      return (
        <FormField label={file.fileName} key={file.fileName}>
          <Button
            variant="primary"
            onClick={() => {
              getFileUrl(file.fileName);
            }}
          >
            Download
          </Button>
        </FormField>
      );
    });

    return (
      <SpaceBetween direction="vertical" size="m">
        {filesAlert}
        {downloadsToReturn}
      </SpaceBetween>
    );
  };
  return (
    <SpaceBetween direction="vertical" size="s">
      <Container
        header={
          <Header variant="h2">
            Authorization for Initial Medical Evaluation
          </Header>
        }
      >
        {gettingFiles ? (
          <RetrievingFileList />
        ) : (
          <SpaceBetween direction="vertical" size="s">
            {renderTpaAlert()}
            {renderErrorAlert()}
            <SpaceBetween direction="vertical" size="l">
              {renderDownloads()}
            </SpaceBetween>
          </SpaceBetween>
        )}
      </Container>
    </SpaceBetween>
  );
};
export default MedicalAuthorizationDocuments;
