export const USA_STATES = [
  { label: "Alabama", value: "AL" },
  { label: "Alaska", value: "AK" },
  { label: "American Samoa", value: "AS" },
  { label: "Arizona", value: "AZ" },
  { label: "Arkansas", value: "AR" },
  { label: "California", value: "CA" },
  { label: "Colorado", value: "CO" },
  { label: "Connecticut", value: "CT" },
  { label: "Delaware", value: "DE" },
  { label: "District of Columbia", value: "DC" },
  { label: "Federated States of Micronesia", value: "FM" },
  { label: "Florida", value: "FL" },
  { label: "Georgia", value: "GA" },
  { label: "Guam", value: "GU" },
  { label: "Hawaii", value: "HI" },
  { label: "Idaho", value: "ID" },
  { label: "Illinois", value: "IL" },
  { label: "Indiana", value: "IN" },
  { label: "Iowa", value: "IA" },
  { label: "Kansas", value: "KS" },
  { label: "Kentucky", value: "KY" },
  { label: "Louisiana", value: "LA" },
  { label: "Maine", value: "ME" },
  { label: "Marshall Islands", value: "MH" },
  { label: "Maryland", value: "MD" },
  { label: "Massachusetts", value: "MA" },
  { label: "Michigan", value: "MI" },
  { label: "Minnesota", value: "MN" },
  { label: "Mississippi", value: "MS" },
  { label: "Missouri", value: "MO" },
  { label: "Montana", value: "MT" },
  { label: "Nebraska", value: "NE" },
  { label: "Nevada", value: "NV" },
  { label: "New Hampshire", value: "NH" },
  { label: "New Jersey", value: "NJ" },
  { label: "New Mexico", value: "NM" },
  { label: "New York", value: "NY" },
  { label: "North Carolina", value: "NC" },
  { label: "North Dakota", value: "ND" },
  { label: "Northern Mariana Islands", value: "MP" },
  { label: "Ohio", value: "OH" },
  { label: "Oklahoma", value: "OK" },
  { label: "Oregon", value: "OR" },
  { label: "Palau", value: "PW" },
  { label: "Pennsylvania", value: "PA" },
  { label: "Puerto Rico", value: "PR" },
  { label: "Rhode Island", value: "RI" },
  { label: "South Carolina", value: "SC" },
  { label: "South Dakota", value: "SD" },
  { label: "Tennessee", value: "TN" },
  { label: "Texas", value: "TX" },
  { label: "Utah", value: "UT" },
  { label: "Vermont", value: "VT" },
  { label: "Virgin Islands", value: "VI" },
  { label: "Virginia", value: "VA" },
  { label: "Washington", value: "WA" },
  { label: "West Virginia", value: "WV" },
  { label: "Wisconsin", value: "WI" },
  { label: "Wyoming", value: "WY" },
];

export const BODY_PART_OPTIONS = [
  { label: "Abdomen Including Groin", value: "6100" },
  { label: "Ankle", value: "5500" },
  { label: "Arm, Multiple", value: "3302" },
  { label: "Artificial Appliance (Braces, Etc.)", value: "6400" },
  { label: "Back (All Other)", value: "4101" },
  { label: "Brain", value: "1200" },
  { label: "Buttocks", value: "6200" },
  { label: "Chest (Incl. Ribs, Sternum & Soft Tissue)", value: "4400" },
  { label: "Ear(s)", value: "1300" },
  { label: "Elbow", value: "3200" },
  { label: "Eye(s)", value: "1400" },
  { label: "Face, Multiple Parts", value: "1002" },
  { label: "Facial Bones", value: "1900" },
  { label: "Finger(s)", value: "3600" },
  { label: "Foot", value: "5600" },
  { label: "Forearm", value: "3301" },
  { label: "Great Toe", value: "5800" },
  { label: "Hand", value: "3500" },
  { label: "Head", value: "1001" },
  { label: "Hip", value: "5100" },
  { label: "Jaw", value: "1901" },
  { label: "Knee", value: "5300" },
  { label: "Leg, Multiple", value: "5001" },
  { label: "Low Back Area (Incl. Lumbar & Lumbo-Sacral)", value: "4200" },
  { label: "Lower Arm", value: "3300" },
  { label: "Lower Leg", value: "5400" },
  { label: "Mouth", value: "1700" },
  { label: "Multiple Body Parts", value: "9000" },
  { label: "Multiple Lower Extremities", value: "5000" },
  { label: "Multiple Trunk", value: "4000" },
  { label: "Multiple Upper Extremities", value: "3000" },
  { label: "No Physical Injury", value: "6600" },
  { label: "Nose", value: "1500" },
  { label: "Other facial soft tissue", value: "1800" },
  { label: "Pelvis", value: "4600" },
  { label: "Sacrum and Coccyx", value: "4500" },
  { label: "Scalp", value: "1101" },
  { label: "Shoulder(s)", value: "3800" },
  { label: "Skull", value: "1100" },
  { label: "Soft Tissue-Neck", value: "2500" },
  { label: "Stress", value: "9001" },
  { label: "Teeth", value: "1600" },
  { label: "Thumb", value: "3700" },
  { label: "Toe(s)", value: "5700" },
  { label: "Upper Arm (Incl. Clavicle & Scapula)", value: "3100" },
  { label: "Upper Back Area (Thoracic Area)", value: "4100" },
  { label: "Upper Leg", value: "5200" },
  { label: "Wrist", value: "3400" },
  { label: "Wrist(s) and Hand(s)", value: "3900" },
];
/*
 * BODY_SIDE_OPTIONS values do not match the expected Sedgwick values of L, R, B, A, P, UNK
 * but the Back-end Service side receives these strings and transforms them to the Sedgwick
 * accepted values accordingly.
 */
export const BODY_SIDE_OPTIONS = [
  { label: "Left", value: "LEFT" },
  { label: "Right", value: "RIGHT" },
  { label: "Bilateral", value: "BILATERAL" },
  { label: "Anterior", value: "ANTERIOR" },
  { label: "Posterior", value: "POSTERIOR" },
  { label: "Unknown", value: "UNKNOWN" },
];
export const SYMPTOM_OPTIONS = [
  { label: "Abnormal Sensation", value: "Abnormal Sensation" },
  { label: "Abrasion/Scratch", value: "Abrasion/Scratch" },
  { label: "Bite/Sting (Animal/Insect)", value: "Bite/Sting (Animal/Insect)" },
  { label: "Bleeding", value: "Bleeding" },
  { label: "Blister", value: "Blister" },
  { label: "Bruising", value: "Bruising" },
  { label: "Burn", value: "Burn" },
  { label: "Cramping", value: "Cramping" },
  { label: "Cut", value: "Cut" },
  { label: "Dryness", value: "Dryness" },
  {
    label: "Foreign Body (e.g., Splinter) ",
    value: "Foreign Body (e.g., Splinter) ",
  },
  { label: "Instability", value: "Instability" },
  { label: "Itchiness", value: "Itchiness" },
  { label: "Loss of limb(s)", value: "Loss of limb(s)" },
  { label: "Numbness", value: "Numbness" },
  { label: "Pain or Soreness", value: "Pain or Soreness" },
  { label: "Puncture (needle stick)", value: "Puncture (needle stick)" },
  {
    label: "Puncture (other than needed stick)",
    value: "Puncture (other than needed stick)",
  },
  { label: "Rash", value: "Rash" },
  { label: "Reduced blood flow", value: "Reduced blood flow" },
  { label: "Reduced muscle function", value: "Reduced muscle function" },
  { label: "Reduced range of motion", value: "Reduced range of motion" },
  { label: "Skin Irritation", value: "Skin Irritation" },
  { label: "Swelling", value: "Swelling" },
  { label: "Tingling", value: "Tingling" },
  { label: "Weakness", value: "Weakness" },
];
export const SEDGWICK_NATURE_OF_INJURY_OPTIONS = [
  { value: "200", label: "Amputation" },
  { value: "5400", label: "Asphyxiation" },
  { value: "400", label: "Burn" },
  { value: "401", label: "Burn or Scald (Heat)" },
  { value: "402", label: "Burn-Chemical" },
  { value: "5908", label: "Chipped / Broken Tooth" },
  { value: "3701", label: "Conjunctivitis" },
  { value: "404", label: "Contact Burn" },
  { value: "1000", label: "Contusion (Bruise)" },
  { value: "8300", label: "COVID-19" },
  { value: "1300", label: "Crushing" },
  { value: "5902", label: "Death" },
  { value: "6800", label: "Dermatitis" },
  { value: "1600", label: "Dislocation" },
  { value: "1900", label: "Electric Shock" },
  { value: "403", label: "Flash Burn" },
  { value: "2500", label: "Foreign Body (Eye)" },
  { value: "2800", label: "Fracture" },
  { value: "3000", label: "Freezing (Frostbite)" },
  { value: "3100", label: "Hearing Loss or Impairment" },
  { value: "3201", label: "Heat Stroke" },
  { value: "3600", label: "Infection" },
  { value: "3700", label: "Inflammation" },
  { value: "6502", label: "Inhalation" },
  { value: "4000", label: "Laceration" },
  { value: "7200", label: "Loss of Hearing" },
  { value: "7700", label: "Mental Stress" },
  { value: "100", label: "No Physical Injury" },
  { value: "4200", label: "Poisoning-General" },
  { value: "4300", label: "Puncture" },
  { value: "4900", label: "Sprain" },
  { value: "5200", label: "Strain" },
  { value: "5401", label: "Strangulation/Drowning" },
  { value: "5300", label: "Syncope" },
  { value: "5800", label: "Vision Loss" },
];
export const SEDGWICK_CAUSE_OF_INJURY_OPTIONS = [
  { value: "8200", label: "Absorption" },
  { value: "8901", label: "Altercation" },
  { value: "6002", label: "Bending" },
  { value: "1901", label: "Bitten" },
  { value: "1500", label: "Broken Glass" },
  { value: "901", label: "Burn or Scald - Liquids" },
  { value: "100", label: "Burn or Scald-Chemicals" },
  { value: "1300", label: "Caught In" },
  { value: "1201", label: "Caught In/Between-Box" },
  { value: "1004", label: "Caught In/Between-Conveyor" },
  { value: "1202", label: "Caught In/Between-Furniture" },
  { value: "1000", label: "Caught In/Between-Machine or Machinery" },
  { value: "1200", label: "Caught In/Between-Object Handled" },
  { value: "304", label: "Cold" },
  { value: "4600", label: "Collision with a Fixed Object" },
  { value: "4500", label: "Collision with Another Vehicle" },
  { value: "1400", label: "Contact with Abnormal Air Pressure" },
  { value: "1100", label: "Contact With Cold Object or Substance" },
  { value: "8400", label: "Contact with Electrical Current" },
  { value: "200", label: "Contact with Hot Object or Substance" },
  { value: "1101", label: "Contact with Ice" },
  { value: "9902", label: "Contact with Infectious Disease" },
  { value: "902", label: "Contact With Latex" },
  { value: "301", label: "Contact with Temperature Extremes" },
  { value: "5200", label: "Continual Noise" },
  { value: "9806", label: "Continuous Trauma" },
  { value: "8300", label: "COVID-19" },
  { value: "9800", label: "Cumulative (All Other)" },
  { value: "1900", label: "Cut" },
  { value: "9805", label: "Drug or Medicine" },
  { value: "600", label: "Dust" },
  { value: "8401", label: "Electric Flash" },
  { value: "3100", label: "Fall" },
  { value: "2900", label: "Fall On the Same Level" },
  { value: "2500", label: "Fall/Slip From a Different Level" },
  { value: "2600", label: "Fall/Slip From Ladder or Scaffolding" },
  { value: "2700", label: "Fall/Slip From Liquid or Grease Spills" },
  { value: "3200", label: "Fall/Slip on Ice or Snow" },
  { value: "3300", label: "Fall/Slip on Stairs" },
  { value: "400", label: "Fire or Flame" },
  { value: "8700", label: "Foreign Body in Eye" },
  { value: "8902", label: "Gunshot/Bullet" },
  { value: "1600", label: "Hand Tool" },
  { value: "303", label: "Heating Equipment" },
  { value: "5500", label: "Holding or Carrying" },
  { value: "5400", label: "Jumping" },
  { value: "5600", label: "Lifting" },
  { value: "5000", label: "Motor Vehicle" },
  { value: "1902", label: "Needle Stick" },
  { value: "9900", label: "Other Injury" },
  { value: "6003", label: "Overexertion" },
  { value: "8900", label: "Person in Act of Crime" },
  { value: "1800", label: "Powered Hand Tool; Appliance" },
  { value: "601", label: "Pressurized" },
  { value: "5700", label: "Pushing or Pulling" },
  { value: "800", label: "Radiation" },
  { value: "5800", label: "Reaching" },
  { value: "9700", label: "Repetitive Motion" },
  { value: "1903", label: "Rubbed or Abraded" },
  { value: "3000", label: "Slipped" },
  { value: "6700", label: "Standing" },
  { value: "500", label: "Steam or Hot Fluids" },
  { value: "6900", label: "Stepping on Sharp Object" },
  { value: "5900", label: "Strain By Using Tool or Machine" },
  { value: "9803", label: "Stress" },
  { value: "6602", label: "Strike Against Building & Structures" },
  { value: "6601", label: "Strike Against Cart" },
  { value: "6500", label: "Strike Against Moving Parts of Machine" },
  { value: "6801", label: "Strike Against Working Surface" },
  {
    value: "6600",
    label: "Strike Against/Step On Object Being Lifted or Handled",
  },
  { value: "6800", label: "Strike Against/Step On Stationary Object" },
  { value: "7000", label: "Striking Against or Stepping On" },
  { value: "8500", label: "Struck/Injured By Animal or Insect" },
  { value: "8107", label: "Struck/Injured By Body Fluid" },
  { value: "7601", label: "Struck/Injured By Electrical Apparatus" },
  { value: "8600", label: "Struck/Injured By Explosion or Flare Back" },
  { value: "7500", label: "Struck/Injured By Falling or Flying Object" },
  { value: "7400", label: "Struck/Injured By Fellow Worker" },
  { value: "7906", label: "Struck/Injured By Food Product" },
  { value: "8104", label: "Struck/Injured By Gunshot/Bullet" },
  { value: "7600", label: "Struck/Injured By Hand Tool or Machine in Use" },
  { value: "7602", label: "Struck/Injured By Hoisting Apparatus" },
  { value: "7904", label: "Struck/Injured By Ladder" },
  { value: "7700", label: "Struck/Injured By Motor Vehicle" },
  { value: "7800", label: "Struck/Injured By Moving Parts of Machine" },
  { value: "7900", label: "Struck/Injured By Object Being Lifted or Handled" },
  { value: "8000", label: "Struck/Injured By Object Handled By Others" },
  { value: "7905", label: "Struck/Injured By Paper Item" },
  { value: "7501", label: "Struck/Injured By Particle" },
  { value: "7907", label: "Struck/Injured By Plant" },
  { value: "7908", label: "Struck/Injured By Plastic Item" },
  { value: "7502", label: "Struck/Injured By Scrap" },
  { value: "3101", label: "Trip" },
  { value: "5300", label: "Twisting" },
  { value: "700", label: "Welding Operations" },
  { value: "6100", label: "Wielding or Throwing" },
];
export const REFERRAL_OPTIONS = [
  {
    label:
      "Associate has met the requirement for referral to outside medical per the WHS Wellness Services Procedure NA",
    value:
      "Associate has met the requirement for referral to outside medical per the WHS Wellness Services Procedure NA",
  },
  {
    label: "Associate has requested outside treatment and/or evaluation",
    value: "Associate has requested outside treatment and/or evaluation",
  },
  {
    label:
      "Associate is being referred by the FA provider as they require treatment greater than first aid.",
    value:
      "Associate is being referred by the FA provider as they require treatment greater than first aid.",
  },
  {
    label:
      "Associate sought treatment outside of the approved provider network after reporting their injury.",
    value:
      "Associate sought treatment outside of the approved provider network after reporting their injury.",
  },
  {
    label:
      "Associate sought treatment outside of the approved provider network before reporting their injury (Bypass).",
    value:
      "Associate sought treatment outside of the approved provider network before reporting their injury (Bypass).",
  },
];
export const CONCERNS_OPTIONS = [
  {
    label: "The Injured Associate declined to provide incident details",
    value: "The Injured Associate declined to provide incident details",
  },
  {
    label:
      "There were inconsistencies surrounding what the associate reported regarding injury details, body parts, incident details, etc.",
    value:
      "There were inconsistencies surrounding what the associate reported regarding injury details, body parts, incident details, etc.",
  },
  {
    label:
      "There is video evidence that is inconsistent with the reported incident details",
    value:
      "There is video evidence that is inconsistent with the reported incident details",
  },
];
export const ANCHOR_ACCIDENT_OPTIONS = [
  {
    label: "Burn, Scald or Cold Exposures - Abnormal Air Pressure",
    value: "14",
  },
  { label: "Burn, Scald or Cold Exposures - Acid Chemicals", value: "1" },
  {
    label: "Burn, Scald or Cold Exposures - Contact With Electric Current",
    value: "84",
  },
  {
    label: "Burn, Scald or Cold Exposures - Contact w/ Hot Object",
    value: "2",
  },
  {
    label: "Burn, Scald or Cold Exposures - Dust, Gases, Fumes or Vapors",
    value: "6",
  },
  { label: "Burn, Scald or Cold Exposures - Fire or Flame", value: "4" },
  { label: "Burn, Scald or Cold Exposures - Radiation", value: "8" },
  { label: "Burn, Scald or Cold Exposures - Steam or Hot Fluids", value: "5" },
  { label: "Burn, Scald or Cold Exposures - Temp. Extremes", value: "3" },
  { label: "Burn, Scald or Cold Exposures - Welding Operations", value: "7" },
  { label: "Caught In or Between - Caught In, Under, or Between", value: "13" },
  {
    label: "Caught In or Between - Collapsing Materials (slides of earth)",
    value: "20",
  },
  { label: "Caught In or Between - Machine or Machinery", value: "10" },
  { label: "Caught In or Between - Object Handled", value: "12" },
  { label: "Cut, Puncture, Scrape Injured - Broken Glass", value: "15" },
  {
    label: "Cut, Puncture, Scrape Injured - Cut, Puncture, Scrape",
    value: "19",
  },
  {
    label: "Cut, Puncture, Scrape Injured - Hand Tool, Utensil: Not Powered",
    value: "16",
  },
  {
    label: "Cut, Puncture, Scrape Injured - Object Being Lifted or Handled",
    value: "17",
  },
  {
    label: "Cut, Puncture, Scrape Injured - Powered Hand Tool, Appliance",
    value: "18",
  },
  { label: "Fall or Slip Injury - Fall or Slip Injury", value: "31" },
  { label: "Fall or Slip Injury - From Different Level", value: "25" },
  { label: "Fall or Slip Injury - From Ladder or Scaffolding", value: "26" },
  { label: "Fall or Slip Injury - From Liquid or Grease Spills", value: "27" },
  {
    label: "Fall or Slip Injury - Into Openings shafts, excavations, floors",
    value: "28",
  },
  { label: "Fall or Slip Injury - On Ice or Snow", value: "32" },
  { label: "Fall or Slip Injury - On Same Level", value: "29" },
  { label: "Fall or Slip Injury - On Stairs", value: "33" },
  { label: "Fall or Slip Injury - Slipped, Did Not Fall", value: "30" },
  {
    label: "Miscellaneous Causes - Absorption, Ingestion or Inhalation",
    value: "82",
  },
  { label: "Miscellaneous Causes - Animal or Insect", value: "85" },
  { label: "Miscellaneous Causes - Cumulative (All Other)", value: "98" },
  { label: "Miscellaneous Causes - Explosion or Flare Back", value: "86" },
  { label: "Miscellaneous Causes - Foreign Body in Eye", value: "87" },
  { label: "Miscellaneous Causes - Other", value: "99" },
  {
    label: "Miscellaneous Causes - Other Than Physical Cause of Injury",
    value: "90",
  },
  { label: "Miscellaneous Causes - Robbery or Criminal Assault", value: "89" },
  { label: "Miscellaneous Causes - Terrorism", value: "96" },
  { label: "Motor Vehicle - Collision With Another Vehicle", value: "45" },
  { label: "Motor Vehicle - Collision With a Fixed Object", value: "46" },
  { label: "Motor Vehicle - Crash of Airplane", value: "47" },
  { label: "Motor Vehicle - Crash of Rail Vehicle", value: "41" },
  { label: "Motor Vehicle - Crash of Water Vehicle", value: "40" },
  { label: "Motor Vehicle - Motor Vehicle", value: "50" },
  {
    label: "Rubbed or Abrated By - Repetitive Motion Callous, blister etc.",
    value: "94",
  },
  { label: "Rubbed or Abrated By - Rubbed or Abrated", value: "95" },
  { label: "Strain or Injury By - Continual Noise", value: "52" },
  { label: "Strain or Injury By - Holding or Carrying", value: "55" },
  { label: "Strain or Injury By - Jumping", value: "54" },
  { label: "Strain or Injury By - Lifting", value: "56" },
  { label: "Strain or Injury By - Pushing or Pulling", value: "57" },
  { label: "Strain or Injury By - Reaching", value: "58" },
  { label: "Strain or Injury By - Repetitive Motion", value: "97" },
  { label: "Strain or Injury By - Strain or Injury By", value: "60" },
  { label: "Strain or Injury By - Twisting", value: "53" },
  { label: "Strain or Injury By - Using Tool or Machine", value: "59" },
  { label: "Strain or Injury By - Wielding or Throwing", value: "61" },
  {
    label: "Striking Against or Stepping on - Moving Part of Machine",
    value: "65",
  },
  {
    label: "Striking Against or Stepping on - Object Being Lifted or Handled",
    value: "66",
  },
  {
    label: "Striking Against or Stepping on - Sanding, Scraping, Cleaning Op",
    value: "67",
  },
  { label: "Striking Against or Stepping on - Stationary Object", value: "68" },
  {
    label: "Striking Against or Stepping on - Stepping on Sharp Object",
    value: "69",
  },
  {
    label: "Striking Against or Stepping on - Striking Against or Stepping on",
    value: "70",
  },
  { label: "Struck or Injured By - Falling or Flying Object", value: "75" },
  { label: "Struck or Injured By - Fellow Worker", value: "74" },
  { label: "Struck or Injured By - Hand Tool or Machine In Use", value: "76" },
  { label: "Struck or Injured By - Motor Vehicle", value: "77" },
  { label: "Struck or Injured By - Moving Parts of Machine", value: "78" },
  {
    label: "Struck or Injured By - Object Being Lifted or Handled",
    value: "79",
  },
  { label: "Struck or Injured By - Object Handled By Others", value: "80" },
  { label: "Struck or Injured By - Struck or Injured By", value: "81" },
];
export const ANCHOR_INJURY_OPTIONS = [
  { label: "Multiple Injuries - Multiple Physical Injuries Only", value: "90" },
  {
    label:
      "Occupational Disease or Cumulative Injury - All Other Cumulative Injuries",
    value: "80",
  },
  {
    label: "Occupational Disease or Cumulative Injury - All Other OD",
    value: "71",
  },
  {
    label: "Occupational Disease or Cumulative Injury - Carpal Tunnel Syndrome",
    value: "78",
  },
  {
    label: "Occupational Disease or Cumulative Injury - Contagious Disease",
    value: "73",
  },
  {
    label: "Occupational Disease or Cumulative Injury - Dermatitis",
    value: "68",
  },
  {
    label: "Occupational Disease or Cumulative Injury - Dust Disease",
    value: "60",
  },
  {
    label: "Occupational Disease or Cumulative Injury - Loss of Hearing",
    value: "72",
  },
  {
    label: "Occupational Disease or Cumulative Injury - Poisoning-Chemical",
    value: "66",
  },
  {
    label: "Occupational Disease or Cumulative Injury - Poisoning-Metal",
    value: "67",
  },
  {
    label: "Occupational Disease or Cumulative Injury - Radiation",
    value: "70",
  },
  {
    label: "Occupational Disease or Cumulative Injury - Respiratory Disorders",
    value: "65",
  },
  { label: "Specific Injury (Trauma) - All Other", value: "59" },
  { label: "Specific Injury (Trauma) - Amputation", value: "2" },
  { label: "Specific Injury (Trauma) - Asphyxiation", value: "54" },
  { label: "Specific Injury (Trauma) - Burn", value: "4" },
  { label: "Specific Injury (Trauma) - Concussion", value: "7" },
  { label: "Specific Injury (Trauma) - Contusion", value: "10" },
  { label: "Specific Injury (Trauma) - Crushing", value: "13" },
  { label: "Specific Injury (Trauma) - Dislocation", value: "16" },
  { label: "Specific Injury (Trauma) - Electric Shock", value: "19" },
  { label: "Specific Injury (Trauma) - Enucleation", value: "22" },
  { label: "Specific Injury (Trauma) - Foreign Body", value: "25" },
  { label: "Specific Injury (Trauma) - Fracture", value: "28" },
  { label: "Specific Injury (Trauma) - Freezing", value: "30" },
  {
    label: "Specific Injury (Trauma) - Hearing Loss (Traumatic Only)",
    value: "31",
  },
  { label: "Specific Injury (Trauma) - Heat Prostration", value: "32" },
  { label: "Specific Injury (Trauma) - Infection", value: "36" },
  { label: "Specific Injury (Trauma) - Inflammation", value: "37" },
  { label: "Specific Injury (Trauma) - Laceration", value: "40" },
  { label: "Specific Injury (Trauma) - No Physical Injury", value: "1" },
  { label: "Specific Injury (Trauma) - Poisoning", value: "42" },
  { label: "Specific Injury (Trauma) - Puncture", value: "43" },
  { label: "Specific Injury (Trauma) - Rupture", value: "46" },
  { label: "Specific Injury (Trauma) - Sprain", value: "49" },
  { label: "Specific Injury (Trauma) - Strain", value: "52" },
  { label: "Specific Injury (Trauma) - Syncope", value: "53" },
  { label: "Specific Injury (Trauma) - Vascular", value: "55" },
  { label: "Specific Injury (Trauma) - Vision Loss", value: "58" },
];
export const ANCHOR_CAUSES_OPTIONS = [
  { label: "Air Pressure", value: "41" },
  { label: "Animals And Insects", value: "70" },
  { label: "Bodily Fluids", value: "25" },
  { label: "Body Movement", value: "74" },
  { label: "Building Structures", value: "71" },
  { label: "Chemicals", value: "42" },
  { label: "Clothing", value: "44" },
  { label: "Conveyors", value: "45" },
  { label: "Crane, Elevators", value: "53" },
  { label: "Debris", value: "73" },
  { label: "Environmental (Noise/Temp)", value: "68" },
  { label: "Excavations", value: "48" },
  { label: "Floor", value: "76" },
  { label: "Food", value: "69" },
  { label: "Furniture/Fixtures", value: "49" },
  { label: "Glass Fibers", value: "51" },
  { label: "Glassware", value: "50" },
  { label: "Ground", value: "55" },
  { label: "Hand Tools (Non-power)", value: "52" },
  { label: "Heating Equipment", value: "54" },
  { label: "Hypodermic Needle", value: "24" },
  { label: "Ladders/Scaffolds/Stairs", value: "56" },
  { label: "Machines", value: "57" },
  { label: "Mechanical Power Transmission", value: "58" },
  { label: "Metal Items", value: "62" },
  { label: "Paper/Sheet Of/Stack Of", value: "75" },
  { label: "Person Other Than Injured", value: "66" },
  { label: "Power Tool/Motors", value: "47" },
  { label: "Radiation", value: "61" },
  { label: "Recreational Activity/Training", value: "67" },
  { label: "Resident/Patient", value: "78" },
  { label: "Sharp Objects", value: "59" },
  { label: "Unknown", value: "77" },
  { label: "Vehicles", value: "63" },
  { label: "Wood Items", value: "64" },
  { label: "Working Surface", value: "65" },
];
export const ANCHOR_BODY_PART_OPTIONS = [
  { label: "Head - Brain", value: "12" },
  { label: "Head - Ear(s) - Bilateral", value: "13:B" },
  { label: "Head - Ear(s) - Left", value: "13:L" },
  { label: "Head - Ear(s) - Right", value: "13:R" },
  { label: "Head - Eye(s) - Bilateral", value: "14:B" },
  { label: "Head - Eye(s) - Left", value: "14:L" },
  { label: "Head - Eye(s) - Right", value: "14:R" },
  { label: "Head - Facial Bones", value: "19" },
  { label: "Head - Mouth", value: "17" },
  { label: "Head - Multiple Head Injury", value: "10" },
  { label: "Head - Nose", value: "15" },
  { label: "Head - Skull", value: "11" },
  { label: "Head - Soft Tissue (Facial)", value: "18" },
  { label: "Head - Teeth", value: "16" },
  { label: "Lower Extremities - Ankle - Bilateral", value: "55:B" },
  { label: "Lower Extremities - Ankle - Left", value: "55:L" },
  { label: "Lower Extremities - Ankle - Right", value: "55:R" },
  { label: "Lower Extremities - Foot - Bilateral", value: "56:B" },
  { label: "Lower Extremities - Foot - Left", value: "56:L" },
  { label: "Lower Extremities - Foot - Right", value: "56:R" },
  { label: "Lower Extremities - Great Toe - Bilateral", value: "58:B" },
  { label: "Lower Extremities - Great Toe - Left", value: "58:L" },
  { label: "Lower Extremities - Great Toe - Right", value: "58:R" },
  { label: "Lower Extremities - Hip - Bilateral", value: "51:B" },
  { label: "Lower Extremities - Hip - Left", value: "51:L" },
  { label: "Lower Extremities - Hip - Right", value: "51:R" },
  { label: "Lower Extremities - Knee - Bilateral", value: "53:B" },
  { label: "Lower Extremities - Knee - Left", value: "53:L" },
  { label: "Lower Extremities - Knee - Right", value: "53:R" },
  { label: "Lower Extremities - Lower Leg - Bilateral", value: "54:B" },
  { label: "Lower Extremities - Lower Leg - Left", value: "54:L" },
  { label: "Lower Extremities - Lower Leg - Right", value: "54:R" },
  {
    label: "Lower Extremities - Mult. Lower Extremities - Bilateral",
    value: "50:B",
  },
  {
    label: "Lower Extremities - Mult. Lower Extremities - Left",
    value: "50:L",
  },
  {
    label: "Lower Extremities - Mult. Lower Extremities - Right",
    value: "50:R",
  },
  { label: "Lower Extremities - Thigh - Bilateral", value: "52:B" },
  { label: "Lower Extremities - Thigh - Left", value: "52:L" },
  { label: "Lower Extremities - Thigh - Right", value: "52:R" },
  {
    label: "Lower Extremities - Toe(s) - Bilateral - Index Finger or 1st Toe",
    value: "57:B:1",
  },
  {
    label:
      "Lower Extremities - Toe(s) - Bilateral - Little Finger or 4th (little) Toe",
    value: "57:B:4",
  },
  {
    label: "Lower Extremities - Toe(s) - Bilateral - Middle Finger or 2nd Toe",
    value: "57:B:2",
  },
  {
    label: "Lower Extremities - Toe(s) - Bilateral - Ring Finger or 3rd Toe",
    value: "57:B:3",
  },
  {
    label: "Lower Extremities - Toe(s) - Left - Index Finger or 1st Toe",
    value: "57:L:1",
  },
  {
    label:
      "Lower Extremities - Toe(s) - Left - Little Finger or 4th (little) Toe",
    value: "57:L:4",
  },
  {
    label: "Lower Extremities - Toe(s) - Left - Middle Finger or 2nd Toe",
    value: "57:L:2",
  },
  {
    label: "Lower Extremities - Toe(s) - Left - Ring Finger or 3rd Toe",
    value: "57:L:3",
  },
  {
    label: "Lower Extremities - Toe(s) - Right - Index Finger or 1st Toe",
    value: "57:R:1",
  },
  {
    label:
      "Lower Extremities - Toe(s) - Right - Little Finger or 4th (little) Toe",
    value: "57:R:4",
  },
  {
    label: "Lower Extremities - Toe(s) - Right - Middle Finger or 2nd Toe",
    value: "57:R:2",
  },
  {
    label: "Lower Extremities - Toe(s) - Right - Ring Finger or 3rd Toe",
    value: "57:R:3",
  },
  { label: "Neck - Multiple Neck Injury", value: "20" },
  { label: "Neck - Soft Tissue (Neck)", value: "25" },
  { label: "Neck - Trachea", value: "26" },
  { label: "Trunk - Abdomen / Groin", value: "61" },
  { label: "Trunk - Buttocks - Bilateral", value: "62:B" },
  { label: "Trunk - Buttocks - Left", value: "62:L" },
  { label: "Trunk - Buttocks - Right", value: "62:R" },
  { label: "Trunk - Chest", value: "44" },
  { label: "Trunk - Low Back Area", value: "42" },
  { label: "Trunk - Multiple Trunk", value: "40" },
  { label: "Trunk - Pelvis", value: "46" },
  { label: "Trunk - Sacrum & Coccyx", value: "45" },
  { label: "Trunk - Upper Back Area (Thoracic)", value: "41" },
  { label: "Upper Extremities - Elbow - Bilateral", value: "32:B" },
  { label: "Upper Extremities - Elbow - Left", value: "32:L" },
  { label: "Upper Extremities - Elbow - Right", value: "32:R" },
  {
    label:
      "Upper Extremities - Finger(s) - Bilateral - Index Finger or 1st Toe",
    value: "36:B:1",
  },
  {
    label:
      "Upper Extremities - Finger(s) - Bilateral - Little Finger or 4th (little) Toe",
    value: "36:B:4",
  },
  {
    label:
      "Upper Extremities - Finger(s) - Bilateral - Middle Finger or 2nd Toe",
    value: "36:B:2",
  },
  {
    label: "Upper Extremities - Finger(s) - Bilateral - Ring Finger or 3rd Toe",
    value: "36:B:3",
  },
  {
    label: "Upper Extremities - Finger(s) - Left - Index Finger or 1st Toe",
    value: "36:L:1",
  },
  {
    label:
      "Upper Extremities - Finger(s) - Left - Little Finger or 4th (little) Toe",
    value: "36:L:4",
  },
  {
    label: "Upper Extremities - Finger(s) - Left - Middle Finger or 2nd Toe",
    value: "36:L:2",
  },
  {
    label: "Upper Extremities - Finger(s) - Left - Ring Finger or 3rd Toe",
    value: "36:L:3",
  },
  {
    label: "Upper Extremities - Finger(s) - Right - Index Finger or 1st Toe",
    value: "36:R:1",
  },
  {
    label:
      "Upper Extremities - Finger(s) - Right - Little Finger or 4th (little) Toe",
    value: "36:R:4",
  },
  {
    label: "Upper Extremities - Finger(s) - Right - Middle Finger or 2nd Toe",
    value: "36:R:2",
  },
  {
    label: "Upper Extremities - Finger(s) - Right - Ring Finger or 3rd Toe",
    value: "36:R:3",
  },
  { label: "Upper Extremities - Hand - Bilateral", value: "35:B" },
  { label: "Upper Extremities - Hand - Left", value: "35:L" },
  { label: "Upper Extremities - Hand - Right", value: "35:R" },
  { label: "Upper Extremities - Lower Arm - Bilateral", value: "33:B" },
  { label: "Upper Extremities - Lower Arm - Left", value: "33:L" },
  { label: "Upper Extremities - Lower Arm - Right", value: "33:R" },
  {
    label: "Upper Extremities - Mult. Upper Extremities - Bilateral",
    value: "30:B",
  },
  {
    label: "Upper Extremities - Mult. Upper Extremities - Left",
    value: "30:L",
  },
  {
    label: "Upper Extremities - Mult. Upper Extremities - Right",
    value: "30:R",
  },
  { label: "Upper Extremities - Shoulder - Bilateral", value: "38:B" },
  { label: "Upper Extremities - Shoulder - Left", value: "38:L" },
  { label: "Upper Extremities - Shoulder - Right", value: "38:R" },
  { label: "Upper Extremities - Thumb - Bilateral", value: "37:B" },
  { label: "Upper Extremities - Thumb - Left", value: "37:L" },
  { label: "Upper Extremities - Thumb - Right", value: "37:R" },
  { label: "Upper Extremities - Upper Arm - Bilateral", value: "31:B" },
  { label: "Upper Extremities - Upper Arm - Left", value: "31:L" },
  { label: "Upper Extremities - Upper Arm - Right", value: "31:R" },
  { label: "Upper Extremities - Wrist - Bilateral", value: "34:B" },
  { label: "Upper Extremities - Wrist - Left", value: "34:L" },
  { label: "Upper Extremities - Wrist - Right", value: "34:R" },
  {
    label: "Upper Extremities - Wrist(s) & Hand(s) - Bilateral",
    value: "39:B",
  },
  { label: "Upper Extremities - Wrist(s) & Hand(s) - Left", value: "39:L" },
  { label: "Upper Extremities - Wrist(s) & Hand(s) - Right", value: "39:R" },
];

export const EMPLOYEES_DESIGNATED_COMPANY_LOCATION = {
  value: "EmployeesDesignatedCompanyLocation",
  label: "Employee's designated or Regular Company Location",
};
export const DIFFERENT_COMPANY_LOCATION = {
  value: "DifferentCompanyLocation",
  label: "Different Company Location",
};
export const NON_COMPANY_LOCATION = {
  value: "NonCompanyLocation",
  label: "Non-Company Location",
};
export const WORKING_FROM_HOME_EMPLOYEES_ADDRESS = {
  value: "WorkingFromHomeEmployeesAddress",
  label: "Employee's Work From Home Location",
};

export const LOCATION_TYPE_OPTIONS = [
  DIFFERENT_COMPANY_LOCATION,
  NON_COMPANY_LOCATION,
  WORKING_FROM_HOME_EMPLOYEES_ADDRESS,
];

export const INITIAL_MEDICAL_TREATMENT_OPTIONS = [
  { value: "NoMedicalTreatment", label: "No Medical Treatment" },
  { value: "MinorOnsiteTreatment", label: "Minor Onsite Treatment" },
  {
    value: "TreatmentAtPhysiciansOffice",
    label: "Treatment At Physician's Office",
  },
  { value: "TreatmentAtClinic", label: "Treatment At Clinic" },
  {
    value: "HospitalEmergencyRoomTreatedAndReleased",
    label: "Hospital Emergency Room - Treated And Released",
  },
  {
    value: "HospitalAdmittedTreatedLongerThan24Hours",
    label: "Hospital Admitted - Treated Longer Than 24 Hours",
  },
  {
    value: "HospitalAdmittedTreatedLessThan24Hours",
    label: "Hospital Admitted - Treated Less Than 24 Hours",
  },
  { value: "TreatmentUnknown", label: "Treatment Unknown" },
];

export const HELMSMAN_BODY_PART_OPTIONS = [
  { value: "61", label: "Abdomen" },
  { value: "55", label: "Ankle" },
  { value: "64", label: "Artificial Appliance" },
  { value: "91", label: "Body Systems and Multiple Body Systems" },
  { value: "12", label: "Brain" },
  { value: "62", label: "Buttocks" },
  { value: "44", label: "Chest Ribs or Sternum" },
  { value: "43", label: "Disc in Low or Middle Back" },
  { value: "22", label: "Disc in Neck" },
  { value: "13", label: "Ears" },
  { value: "32", label: "Elbow" },
  { value: "14", label: "Eye" },
  { value: "19", label: "Facial Bones" },
  { value: "18", label: "Facial Soft Tissue" },
  { value: "36", label: "Finger" },
  { value: "56", label: "Foot" },
  { value: "58", label: "Great Toe" },
  { value: "35", label: "Hand" },
  { value: "10", label: "Head" },
  { value: "49", label: "Heart" },
  { value: "51", label: "Hip" },
  { value: "48", label: "Internal Organs" },
  { value: "53", label: "Knee" },
  { value: "24", label: "Larynx" },
  { value: "33", label: "Lower Arm" },
  { value: "42", label: "Lower back - Lumbar or Lumbosacral Area" },
  { value: "50", label: "Lower Extremities" },
  { value: "54", label: "Lower Leg" },
  { value: "63", label: "Lumbar and/or Sacral Vertebrae" },
  { value: "60", label: "Lungs" },
  { value: "17", label: "Mouth" },
  { value: "90", label: "Multiple Body Parts" },
  { value: "20", label: "Neck" },
  { value: "25", label: "Neck - Soft Tissue" },
  { value: "66", label: "No Physical Injury" },
  { value: "15", label: "Nose" },
  { value: "46", label: "Pelvis" },
  { value: "45", label: "Sacrum or Coccyx" },
  { value: "38", label: "Shoulder" },
  { value: "11", label: "Skull" },
  { value: "47", label: "Spinal Cord - Low or Middle Back Area" },
  { value: "23", label: "Spinal Cord - Neck Area" },
  { value: "16", label: "Teeth" },
  { value: "52", label: "Thigh" },
  { value: "37", label: "Thumb" },
  { value: "57", label: "Toe" },
  { value: "26", label: "Trachea" },
  { value: "40", label: "Trunk" },
  { value: "65", label: "Unknown" },
  { value: "31", label: "Upper Arm - Clavicule or Scapula" },
  { value: "41", label: "Upper Back - Thoracic Area" },
  { value: "30", label: "Upper Extremities" },
  { value: "21", label: "Vertebrae in Neck" },
  { value: "34", label: "Wrist" },
  { value: "39", label: "Wrist and Hand" },
];

export const HELMSMAN_NATURE_OF_INJURY = [
  { value: "75", label: "Acquired Immunodeficiency Syndrome" },
  { value: "38", label: "Adverse Reaction to Vaccination or Inoculation" },
  { value: "02", label: "Amputation" },
  { value: "03", label: "Angina Pectoris" },
  { value: "54", label: "Asphyxiation" },
  { value: "04", label: "Burn" },
  { value: "74", label: "Cancer" },
  { value: "78", label: "Carpal Tunnel Syndrome" },
  { value: "07", label: "Concussion" },
  { value: "73", label: "Contagious Disease" },
  { value: "10", label: "Contusion" },
  { value: "83", label: "Covid-19 Coronavirus" },
  { value: "13", label: "Crushing" },
  { value: "80", label: "Cumulative Injury" },
  { value: "68", label: "Dermatitis" },
  { value: "16", label: "Dislocation" },
  { value: "60", label: "Dust Exposure" },
  { value: "19", label: "Electric Shock" },
  { value: "22", label: "Enucleation" },
  { value: "25", label: "Foreign Body" },
  { value: "28", label: "Fracture" },
  { value: "30", label: "Freezing" },
  { value: "72", label: "Hearing Loss Non-Traumatic" },
  { value: "31", label: "Hearing Loss Traumatic" },
  { value: "32", label: "Heat Prostration" },
  { value: "34", label: "Hernia" },
  { value: "36", label: "Infection" },
  { value: "37", label: "Inflamation" },
  { value: "40", label: "Laceration" },
  { value: "61", label: "Lung Disease Asbestosis" },
  { value: "63", label: "Lung Disease Byssinosis" },
  { value: "62", label: "Lung Disease Pneumoconiosis" },
  { value: "64", label: "Lung Disease Silicosis" },
  { value: "69", label: "Mental Disorder" },
  { value: "77", label: "Mental Stress" },
  { value: "91", label: "Multiple Injuries" },
  { value: "90", label: "Multiple Physical Injuries" },
  { value: "41", label: "Myocardial Infarction" },
  { value: "01", label: "No Physical Injury" },
  { value: "71", label: "Occupational Disease" },
  { value: "59", label: "Other Injury Not Otherwise Categorized" },
  { value: "66", label: "Poisoning Chemical" },
  { value: "67", label: "Poisoning Metal" },
  { value: "42", label: "Poisoning Other Than Chemical or Metal" },
  { value: "43", label: "Puncture" },
  { value: "70", label: "Radiation" },
  { value: "65", label: "Respiratory Disorder" },
  { value: "46", label: "Rupture" },
  { value: "47", label: "Severance" },
  { value: "49", label: "Sprain" },
  { value: "52", label: "Strain" },
  { value: "53", label: "Syncope" },
  { value: "55", label: "Vascular" },
  { value: "76", label: "Video Display Terminal Related Disease" },
  { value: "58", label: "Vision Loss" },
];

export const HELMSMAN_BODY_SIDE_OPTIONS = [
  { label: "Left", value: "LEFT" },
  { label: "Right", value: "RIGHT" },
  { label: "Bilateral", value: "BILATERAL" },
  { label: "Unknown", value: "UNKNOWN" },
];

export const HELMSMAN_CAUSE_OF_INJURY_OPTIONS = [
  { value: "82", label: "Absorption Ingestion Inhalation Vaccination" },
  {
    value: "89",
    label: "Act of Crime Robbery or Criminal Assault by Other Person",
  },
  { value: "14", label: "Burn or Scald by Abnormal Air Pressure" },
  { value: "01", label: "Burn or Scald by Chemicals" },
  { value: "11", label: "Burn or Scald by Cold Objects or Substances" },
  { value: "06", label: "Burn or Scald by Dust Gases Fumes or Vapors" },
  { value: "84", label: "Burn or Scald by Electric Current" },
  { value: "04", label: "Burn or Scald by Fire or Flame" },
  { value: "02", label: "Burn or Scald by Hot Object or Substance" },
  { value: "08", label: "Burn or Scald by Radiation" },
  { value: "05", label: "Burn or Scald by Steam or Hot Fluids" },
  { value: "03", label: "Burn or Scald by Temperature Extremes" },
  { value: "07", label: "Burn or Scald by Welding Operation" },
  {
    value: "20",
    label: "Caught in Under or Between Collapsing Materials Slides of Earth",
  },
  { value: "10", label: "Caught in Under or Between Machine or Machinery" },
  { value: "12", label: "Caught in Under or Between Object Handled" },
  { value: "47", label: "Crash of Airplane" },
  { value: "41", label: "Crash of Rail Vehicle" },
  { value: "40", label: "Crash of Water Vehicle" },
  { value: "98", label: "Cumulative Injury" },
  { value: "15", label: "Cut Punctured Scraped by Broken Glass" },
  {
    value: "16",
    label: "Cut Punctured Scraped by Hand Tool Utensil Not Powered",
  },
  {
    value: "17",
    label: "Cut Punctured Scraped by Object Being Lifted or Handled",
  },
  {
    value: "18",
    label: "Cut Punctured Scraped by Powered Hand Tool Appliance",
  },
  { value: "87", label: "Foreign Matter in Eye" },
  { value: "67", label: "Injured by Sanding Scraping or Cleaning Operations" },
  { value: "99", label: "Miscellaneous Not Otherwise Categorized Causes" },
  { value: "91", label: "Mold" },
  { value: "45", label: "Motor Vehicle Collision With Another Vehicle" },
  { value: "46", label: "Motor Vehicle Collision With Fixed Object" },
  { value: "48", label: "Motor Vehicle Upset" },
  { value: "88", label: "Natural Disasters Earthquake Hurricane Tornado Etc" },
  { value: "13", label: "Other Caught in Under or Between" },
  { value: "09", label: "Other Cause of Burn or Scald" },
  { value: "19", label: "Other Cause of Cut Puncture Scrape" },
  { value: "31", label: "Other Cause of Slip Fall or Trip" },
  { value: "60", label: "Other Cause of Strain" },
  { value: "50", label: "Other Motor Vehicle Cause of Injury" },
  { value: "95", label: "Other Rubbed or Abraded by Causes" },
  { value: "70", label: "Other Struck Against or Stepped on Causes" },
  { value: "81", label: "Other Struck or Injured by Causes" },
  { value: "90", label: "Other Than Physical Cause of Injury" },
  { value: "83", label: "Pandemic" },
  { value: "94", label: "Rubbed or Abraded by Repetitive Motion" },
  { value: "25", label: "Slip Fall or Trip From Different Level" },
  { value: "26", label: "Slip Fall or Trip From Ladder or Scaffolding" },
  { value: "27", label: "Slip Fall or Trip From Liquid or Grease Spills" },
  { value: "28", label: "Slip Fall or Trip Into Opening" },
  { value: "32", label: "Slip Fall or Trip on Ice or Snow" },
  { value: "29", label: "Slip Fall or Trip on Same Level" },
  { value: "33", label: "Slip Fall or Trip on Stairs" },
  { value: "30", label: "Slip or Trip Did Not Fall" },
  { value: "52", label: "Strain or Injury by Continual Noise" },
  { value: "55", label: "Strain or Injury by Holding or Carrying" },
  { value: "54", label: "Strain or Injury by Jumping or Leaping" },
  { value: "56", label: "Strain or Injury by Lifting" },
  { value: "57", label: "Strain or Injury by Pushing or Pulling" },
  { value: "58", label: "Strain or Injury by Reaching" },
  { value: "97", label: "Strain or Injury by Repetitive Motion" },
  { value: "53", label: "Strain or Injury by Twisting" },
  { value: "59", label: "Strain or Injury by Using Tool or Machine" },
  { value: "61", label: "Strain or Injury by Wielding or Throwing" },
  {
    value: "65",
    label: "Struck Against or Stepped on Moving Parts of Machine",
  },
  {
    value: "66",
    label: "Struck Against or Stepped on Object Being Lifted or Handled",
  },
  { value: "69", label: "Struck Against or Stepped on Sharp Object" },
  { value: "68", label: "Struck Against or Stepped on Stationary Object" },
  { value: "85", label: "Struck or Injured by Animal or Insect" },
  { value: "86", label: "Struck or Injured by Explosion or Flare Back" },
  { value: "79", label: "Struck or Injured by Object Being Lifted or Handled" },
  {
    value: "75",
    label: "Struck or Injured by Object Falling or Flying Object",
  },
  {
    value: "76",
    label: "Struck or Injured by Object Hand Tool or Machine in Use",
  },
  { value: "80", label: "Struck or Injured by Object Handled by Others" },
  { value: "77", label: "Struck or Injured by Object Motor Vehicle" },
  { value: "78", label: "Struck or Injured by Object Moving Parts of Machine" },
  {
    value: "74",
    label: "Struck or Injured by Person Fellow Worker Patient or Other",
  },
  { value: "96", label: "Terrorism" },
];
export const HELMSMAN_CONCERNS_OPTIONS = [
  {
    label: "No details from associate",
    value: "The Injured Associate declined to provide incident details",
  },
  {
    label:
      "There were inconsistencies surrounding what the associate reported regarding injury details, body parts, incident details, etc.",
    value: "Associate report was inconsistent",
  },
  {
    label:
      "There is video evidence that is inconsistent with the reported incident details",
    value: "Video evidence inconsistent with report",
  },
];
