import { INCIDENT_TIME_AND_PLACE_CONSTANTS } from "../../constants";
import { FormStateType } from "../../types";

export const formFieldsState: FormStateType = {
  [INCIDENT_TIME_AND_PLACE_CONSTANTS.HOME_SITE_EQUAL_TO_INCIDENT_SITE]: {
    value: "true",
    errors: [],
    hidden: false,
  },
  [INCIDENT_TIME_AND_PLACE_CONSTANTS.INCIDENT_SITE_NAME]: {
    value: "",
    errors: [],
    hidden: true,
  },
  [INCIDENT_TIME_AND_PLACE_CONSTANTS.LOCATION_TYPE]: {
    value: {
      value: "EmployeesDesignatedCompanyLocation",
      label: "Employee's designated or Regular Company Location",
    },
    errors: [],
    hidden: true,
  },
  [INCIDENT_TIME_AND_PLACE_CONSTANTS.INCIDENT_DATE]: {
    value: "",
    errors: [],
    hidden: false,
  },
  [INCIDENT_TIME_AND_PLACE_CONSTANTS.INCIDENT_TIME]: {
    value: "",
    errors: [],
    hidden: false,
  },
  [INCIDENT_TIME_AND_PLACE_CONSTANTS.EMPLOYER_NOTIFIED_DATE]: {
    value: "",
    errors: [],
    hidden: false,
  },
  [INCIDENT_TIME_AND_PLACE_CONSTANTS.EMPLOYER_NOTIFIED_TIME]: {
    value: "",
    errors: [],
    hidden: false,
  },
};
