import {
  FormFieldConfigType,
  SelectFormField,
  DatePickerFormField,
  TimeInputFormField,
  RadioFormField,
  INPUT_TYPES,
  RADIO_YES_NO,
} from "../../types";

import { preProcessFormFields } from "../../tools";
import { INCIDENT_TIME_AND_PLACE_CONSTANTS } from "../../constants";
import {
  getSite,
  listSites,
  setIncidentSiteAsHomeSite,
} from "src/sites/sitesSlice";
import { SiteTypes } from "src/sites/constants";
import { SelectProps } from "@amzn/awsui-components-react";
import {
  DIFFERENT_COMPANY_LOCATION,
  EMPLOYEES_DESIGNATED_COMPANY_LOCATION,
  LOCATION_TYPE_OPTIONS,
} from "../../options";
export const formFieldsConfig: FormFieldConfigType[] = [
  {
    name: INCIDENT_TIME_AND_PLACE_CONSTANTS.ALL_FIELDS,
    type: INPUT_TYPES.FIELD_GROUP,
    contained: true,
    label: "Time and place details",

    fields: [
      {
        name: INCIDENT_TIME_AND_PLACE_CONSTANTS.HOME_SITE_EQUAL_TO_INCIDENT_SITE,
        label: "Did the injury/illness occur at the associate's home site?",
        description: "",
        placeholderText: "hh:mm",
        type: INPUT_TYPES.RADIO,
        options: RADIO_YES_NO,
        hasPopover: true,
        popoverText: "Home site is where the associate normally works.",
        onChange: (
          { detail }: { detail: any },
          formState,
          updateForm,
          field,
          dispatch
        ) => {
          // Show locationType when user selects "No"
          const locationTypeHidden = detail.value === "true" ? true : false;
          const locationTypeValue = locationTypeHidden
            ? EMPLOYEES_DESIGNATED_COMPANY_LOCATION
            : "";

          // If user selects homeSite to be the incidentSite we need to set it in the redux state
          if (locationTypeHidden && dispatch) {
            dispatch(setIncidentSiteAsHomeSite());
          }
          const incidentSiteName =
            formState[INCIDENT_TIME_AND_PLACE_CONSTANTS.INCIDENT_SITE_NAME];
          const locationType =
            formState[INCIDENT_TIME_AND_PLACE_CONSTANTS.LOCATION_TYPE];
          const homeSiteEqualToIncidentSite = { ...field.state };

          // Only show the incident site if the user has previously selected
          // "Different Company Location"
          const incidentSiteNameHidden =
            locationType.value.value === DIFFERENT_COMPANY_LOCATION.value
              ? false
              : true;
          updateForm({
            [INCIDENT_TIME_AND_PLACE_CONSTANTS.INCIDENT_SITE_NAME]: {
              ...incidentSiteName,
              hidden: locationTypeHidden ? true : incidentSiteNameHidden,
              value: locationTypeHidden ? "" : incidentSiteName.value,
            },
            [INCIDENT_TIME_AND_PLACE_CONSTANTS.HOME_SITE_EQUAL_TO_INCIDENT_SITE]:
              {
                ...homeSiteEqualToIncidentSite,
                value: detail.value,
              },
            [INCIDENT_TIME_AND_PLACE_CONSTANTS.LOCATION_TYPE]: {
              ...locationType,
              hidden: locationTypeHidden,
              value: locationTypeValue,
            },
          });
        },
      } as RadioFormField,
      {
        name: INCIDENT_TIME_AND_PLACE_CONSTANTS.LOCATION_TYPE,
        label: "What type of location did the incident occur at?",
        description: "",
        placeholderText:
          "Please select the type of location of the incident/injury",
        hasPopover: false,
        type: INPUT_TYPES.SELECT,
        options: LOCATION_TYPE_OPTIONS,
        onChange: (
          { detail }: { detail: any },
          formState,
          updateForm,
          field,
          dispatch
        ) => {
          const { selectedOption } = detail;
          // If user selects "Different Company Location" show the incidentSite dropdown
          const hidden =
            selectedOption.value === DIFFERENT_COMPANY_LOCATION.value
              ? false
              : true;
          const incidentSiteName =
            formState[INCIDENT_TIME_AND_PLACE_CONSTANTS.INCIDENT_SITE_NAME];
          const locationType = { ...field.state };
          if (!hidden && dispatch) {
            dispatch(listSites({ bypassCache: false }));
          }
          // If user selects some other value different than "DifferentCompanyLocation",
          //we need to set incidentSite to homeSite
          else if (hidden && dispatch) {
            dispatch(setIncidentSiteAsHomeSite());
          }

          updateForm({
            [INCIDENT_TIME_AND_PLACE_CONSTANTS.INCIDENT_SITE_NAME]: {
              ...incidentSiteName,
              hidden,
              value: hidden ? "" : incidentSiteName.value,
            },
            [INCIDENT_TIME_AND_PLACE_CONSTANTS.LOCATION_TYPE]: {
              ...locationType,
              value: selectedOption,
              errors: selectedOption.value ? [] : locationType.errors,
            },
          });
        },
      } as SelectFormField,
      {
        name: INCIDENT_TIME_AND_PLACE_CONSTANTS.INCIDENT_SITE_NAME,
        label: "Please select the site where the injury occurred",
        description: "",
        placeholderText: "Please select the incident site",
        hasPopover: false,
        popoverText: "",
        type: INPUT_TYPES.SELECT,
        options: [],
        onChange: (
          { detail }: { detail: any },
          formState,
          updateForm,
          field,
          dispatch
        ) => {
          const { selectedOption } = detail;
          if (selectedOption && dispatch) {
            dispatch(
              getSite({
                code: selectedOption.value,
                type: SiteTypes.INCIDENT_SITE,
              })
            );
          }

          updateForm({
            [INCIDENT_TIME_AND_PLACE_CONSTANTS.INCIDENT_SITE_NAME]: {
              ...field.state,
              value: selectedOption,
              errors: [],
            },
          });
        },
        renderHighlightedAriaLive: (option: SelectProps.Option) => {
          const { label } = option;
          const labelArray = label!.split("");
          labelArray.splice(1, 0, ".");
          labelArray.splice(3, 0, ".");
          labelArray.splice(5, 0, ".");
          return labelArray.join("");
        },
      } as SelectFormField,
      {
        name: INCIDENT_TIME_AND_PLACE_CONSTANTS.INCIDENT_DATE,
        label: "Incident Date",
        description: "Input Date in YYYY/MM/DD format",
        placeholderText: "YYYY/MM/DD",
        hasPopover: false,
        popoverText: "",
        type: INPUT_TYPES.DATE_PICKER,
        constraintText: "",
      } as DatePickerFormField,
      {
        name: INCIDENT_TIME_AND_PLACE_CONSTANTS.INCIDENT_TIME,
        label: "Incident Time",
        description: "Input time in 24h format (hh:mm)",
        placeholderText: "hh:mm",
        hasPopover: false,
        popoverText: "",
        type: INPUT_TYPES.TIME,
        timeFormat: "hh:mm",
        constraintText: "",
      } as TimeInputFormField,
      {
        name: INCIDENT_TIME_AND_PLACE_CONSTANTS.EMPLOYER_NOTIFIED_DATE,
        label: "Employer Notified Date",
        description: "Input Date in YYYY/MM/DD format",
        placeholderText: "YYYY/MM/DD",
        hasPopover: true,
        popoverText:
          "Please select the date when the injured associate first reported the incident to their manager, HR or safety team member.",
        type: INPUT_TYPES.DATE_PICKER,
      } as DatePickerFormField,
      {
        name: INCIDENT_TIME_AND_PLACE_CONSTANTS.EMPLOYER_NOTIFIED_TIME,
        label: "Employer Notified Time",
        description: "Input time in 24h format (hh:mm)",
        placeholderText: "hh:mm",
        hasPopover: true,
        popoverText:
          "Please select the time when the injured associate first reported the incident to thier manager, HR or safety team member.",
        type: INPUT_TYPES.TIME,
        timeFormat: "hh:mm",
        constraintText: "",
        validateWith: INCIDENT_TIME_AND_PLACE_CONSTANTS.EMPLOYER_NOTIFIED_DATE,
      } as TimeInputFormField,
    ],
  },
];

export const formFieldsConfigProcessed = preProcessFormFields(formFieldsConfig);
