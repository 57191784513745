export type QuicklinkSeverityValue = "ONE" | "TWO" | "THREE" | "FOUR" | "FIVE";
export enum SIM_TYPES {
  CLAIM_SUBMITTED = "claimSubmitted",
  FILE_ATTACHMENT_ISSUE = "fileAttachmentIssue",
  UNKNOWN_ERROR = "unknownError",
  REPORT_A_BUG = "reportABug",
  SUGGEST_A_FEATURE = "suggestAFeature",
  TIMEOUT_ERROR = "timeoutError",
  REPORT_MISSING_SITE = "reportMissingSite",
  REQUEST_SITE_MODIFICATION = "requestSiteModification",
}
export enum SIM_TAGS {
  CUSTOMER_REPORTED = "CustomerReported",
  ISSUE = "Issue",
  REQUEST = "Request",
}
