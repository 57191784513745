import { CUSTOM_VALIDATION_TYPES, FormValidationType } from "../../types";
import {
  GENERAL_FORM_CONSTANTS,
  INCIDENT_TIME_AND_PLACE_CONSTANTS,
} from "../../constants";

export const formFieldsValidation: FormValidationType = {
  [INCIDENT_TIME_AND_PLACE_CONSTANTS.INCIDENT_SITE_NAME]: {
    required: {
      value: true,
      message: "Incident Site information is required.",
    },
    maxCharacters: { value: 80 },
  },
  [INCIDENT_TIME_AND_PLACE_CONSTANTS.HOME_SITE_EQUAL_TO_INCIDENT_SITE]: {
    required: { value: true },
  },
  [INCIDENT_TIME_AND_PLACE_CONSTANTS.LOCATION_TYPE]: {
    required: {
      value: true,
      message: "Please answer this question before proceeding.",
    },
  },
  [INCIDENT_TIME_AND_PLACE_CONSTANTS.INCIDENT_DATE]: {
    required: { value: true },
    [CUSTOM_VALIDATION_TYPES.DATE_IS_NOT_5_YEARS_IN_THE_PAST]: {
      value: true,
      message:
        INCIDENT_TIME_AND_PLACE_CONSTANTS.INCIDENT_DATE_5_YEARS_IN_THE_PAST_ERROR,
    },
    [CUSTOM_VALIDATION_TYPES.DATE_IS_BEFORE_OR_EQUAL]: {
      value: true,
      compareTo: {
        value: INCIDENT_TIME_AND_PLACE_CONSTANTS.EMPLOYER_NOTIFIED_DATE,
        message:
          INCIDENT_TIME_AND_PLACE_CONSTANTS.EMPLOYER_NOTIFIED_DATE_BEFORE_INCIDENT_DATE_ERROR,
      },
      message:
        INCIDENT_TIME_AND_PLACE_CONSTANTS.INCIDENT_DATE_AFTER_EMPLOYER_DATE_ERROR,
    },
    [CUSTOM_VALIDATION_TYPES.DATE_TIME_IS_BEFORE]: {
      value: true,
      joinWith: {
        value: INCIDENT_TIME_AND_PLACE_CONSTANTS.INCIDENT_TIME,
        message:
          INCIDENT_TIME_AND_PLACE_CONSTANTS.INCIDENT_DATE_TIME_AFTER_EMPLOYER_DATE_TIME_ERROR,
      },
      compareTime: {
        value: INCIDENT_TIME_AND_PLACE_CONSTANTS.EMPLOYER_NOTIFIED_TIME,
        message:
          INCIDENT_TIME_AND_PLACE_CONSTANTS.EMPLOYER_NOTIFIED_DATE_TIME_BEFORE_INCIDENT_DATE_TIME_ERROR,
      },
      compareDate: {
        value: INCIDENT_TIME_AND_PLACE_CONSTANTS.EMPLOYER_NOTIFIED_DATE,
        message:
          INCIDENT_TIME_AND_PLACE_CONSTANTS.EMPLOYER_NOTIFIED_DATE_TIME_BEFORE_INCIDENT_DATE_TIME_ERROR,
      },
      message:
        INCIDENT_TIME_AND_PLACE_CONSTANTS.INCIDENT_DATE_TIME_AFTER_EMPLOYER_DATE_TIME_ERROR,
    },
    [CUSTOM_VALIDATION_TYPES.DATE_TIME_IS_NOT_IN_FUTURE]: {
      value: true,
      joinWith: {
        value: INCIDENT_TIME_AND_PLACE_CONSTANTS.INCIDENT_TIME,
        message:
          INCIDENT_TIME_AND_PLACE_CONSTANTS.INCIDENT_DATE_TIME_IN_THE_FUTURE_ERROR,
      },
      message:
        INCIDENT_TIME_AND_PLACE_CONSTANTS.INCIDENT_DATE_TIME_IN_THE_FUTURE_ERROR,
    },
  },
  [INCIDENT_TIME_AND_PLACE_CONSTANTS.INCIDENT_TIME]: {
    required: { value: true },
    matchRegExp: {
      value: new RegExp(GENERAL_FORM_CONSTANTS.TIME_FORMAT_REGEX),
      message: GENERAL_FORM_CONSTANTS.TIME_FORMAT_ERROR,
    },
    [CUSTOM_VALIDATION_TYPES.DATE_TIME_IS_BEFORE]: {
      value: true,
      joinWith: {
        value: INCIDENT_TIME_AND_PLACE_CONSTANTS.INCIDENT_DATE,
        message:
          INCIDENT_TIME_AND_PLACE_CONSTANTS.INCIDENT_DATE_TIME_AFTER_EMPLOYER_DATE_TIME_ERROR,
      },
      compareTime: {
        value: INCIDENT_TIME_AND_PLACE_CONSTANTS.EMPLOYER_NOTIFIED_TIME,
        message:
          INCIDENT_TIME_AND_PLACE_CONSTANTS.EMPLOYER_NOTIFIED_DATE_TIME_BEFORE_INCIDENT_DATE_TIME_ERROR,
      },
      compareDate: {
        value: INCIDENT_TIME_AND_PLACE_CONSTANTS.EMPLOYER_NOTIFIED_DATE,
        message:
          INCIDENT_TIME_AND_PLACE_CONSTANTS.EMPLOYER_NOTIFIED_DATE_TIME_BEFORE_INCIDENT_DATE_TIME_ERROR,
      },
      message:
        INCIDENT_TIME_AND_PLACE_CONSTANTS.INCIDENT_DATE_TIME_AFTER_EMPLOYER_DATE_TIME_ERROR,
    },
    [CUSTOM_VALIDATION_TYPES.DATE_TIME_IS_NOT_IN_FUTURE]: {
      value: true,
      joinWith: {
        value: INCIDENT_TIME_AND_PLACE_CONSTANTS.INCIDENT_DATE,
        message:
          INCIDENT_TIME_AND_PLACE_CONSTANTS.INCIDENT_DATE_TIME_IN_THE_FUTURE_ERROR,
      },
      message:
        INCIDENT_TIME_AND_PLACE_CONSTANTS.INCIDENT_DATE_TIME_IN_THE_FUTURE_ERROR,
    },
  },
  [INCIDENT_TIME_AND_PLACE_CONSTANTS.EMPLOYER_NOTIFIED_DATE]: {
    required: { value: true },
    [CUSTOM_VALIDATION_TYPES.DATE_IS_NOT_5_YEARS_IN_THE_PAST]: {
      value: true,
      message:
        INCIDENT_TIME_AND_PLACE_CONSTANTS.EMPLOYER_NOTIFIED_DATE_5_YEARS_IN_THE_PAST_ERROR,
    },
    [CUSTOM_VALIDATION_TYPES.DATE_IS_AFTER_OR_EQUAL]: {
      value: true,
      compareTo: {
        value: INCIDENT_TIME_AND_PLACE_CONSTANTS.INCIDENT_DATE,
        message:
          INCIDENT_TIME_AND_PLACE_CONSTANTS.INCIDENT_DATE_AFTER_EMPLOYER_DATE_ERROR,
      },
      message:
        INCIDENT_TIME_AND_PLACE_CONSTANTS.EMPLOYER_NOTIFIED_DATE_BEFORE_INCIDENT_DATE_ERROR,
    },
    [CUSTOM_VALIDATION_TYPES.DATE_TIME_IS_AFTER]: {
      value: true,
      joinWith: {
        value: INCIDENT_TIME_AND_PLACE_CONSTANTS.EMPLOYER_NOTIFIED_TIME,
        message:
          INCIDENT_TIME_AND_PLACE_CONSTANTS.EMPLOYER_NOTIFIED_DATE_TIME_BEFORE_INCIDENT_DATE_TIME_ERROR,
      },
      compareTime: {
        value: INCIDENT_TIME_AND_PLACE_CONSTANTS.INCIDENT_TIME,
        message:
          INCIDENT_TIME_AND_PLACE_CONSTANTS.INCIDENT_DATE_TIME_AFTER_EMPLOYER_DATE_TIME_ERROR,
      },
      compareDate: {
        value: INCIDENT_TIME_AND_PLACE_CONSTANTS.INCIDENT_DATE,
        message:
          INCIDENT_TIME_AND_PLACE_CONSTANTS.INCIDENT_DATE_TIME_AFTER_EMPLOYER_DATE_TIME_ERROR,
      },
      message:
        INCIDENT_TIME_AND_PLACE_CONSTANTS.EMPLOYER_NOTIFIED_DATE_TIME_BEFORE_INCIDENT_DATE_TIME_ERROR,
    },
    [CUSTOM_VALIDATION_TYPES.DATE_TIME_IS_NOT_IN_FUTURE]: {
      value: true,
      joinWith: {
        value: INCIDENT_TIME_AND_PLACE_CONSTANTS.EMPLOYER_NOTIFIED_TIME,
        message:
          INCIDENT_TIME_AND_PLACE_CONSTANTS.EMPLOYER_NOTIFIED_DATE_TIME_IN_THE_FUTURE_ERROR,
      },
      message:
        INCIDENT_TIME_AND_PLACE_CONSTANTS.EMPLOYER_NOTIFIED_DATE_TIME_IN_THE_FUTURE_ERROR,
    },
  },
  [INCIDENT_TIME_AND_PLACE_CONSTANTS.EMPLOYER_NOTIFIED_TIME]: {
    required: { value: true },
    matchRegExp: {
      value: new RegExp(GENERAL_FORM_CONSTANTS.TIME_FORMAT_REGEX),
      message: GENERAL_FORM_CONSTANTS.TIME_FORMAT_ERROR,
    },
    [CUSTOM_VALIDATION_TYPES.DATE_TIME_IS_AFTER]: {
      value: true,
      joinWith: {
        value: INCIDENT_TIME_AND_PLACE_CONSTANTS.EMPLOYER_NOTIFIED_DATE,
        message:
          INCIDENT_TIME_AND_PLACE_CONSTANTS.EMPLOYER_NOTIFIED_DATE_TIME_BEFORE_INCIDENT_DATE_TIME_ERROR,
      },
      compareTime: {
        value: INCIDENT_TIME_AND_PLACE_CONSTANTS.INCIDENT_TIME,
        message:
          INCIDENT_TIME_AND_PLACE_CONSTANTS.INCIDENT_DATE_TIME_AFTER_EMPLOYER_DATE_TIME_ERROR,
      },
      compareDate: {
        value: INCIDENT_TIME_AND_PLACE_CONSTANTS.INCIDENT_DATE,
        message:
          INCIDENT_TIME_AND_PLACE_CONSTANTS.INCIDENT_DATE_TIME_AFTER_EMPLOYER_DATE_TIME_ERROR,
      },
      message:
        INCIDENT_TIME_AND_PLACE_CONSTANTS.EMPLOYER_NOTIFIED_DATE_TIME_BEFORE_INCIDENT_DATE_TIME_ERROR,
    },
    [CUSTOM_VALIDATION_TYPES.DATE_TIME_IS_NOT_IN_FUTURE]: {
      value: true,
      joinWith: {
        value: INCIDENT_TIME_AND_PLACE_CONSTANTS.EMPLOYER_NOTIFIED_DATE,
        message:
          INCIDENT_TIME_AND_PLACE_CONSTANTS.EMPLOYER_NOTIFIED_DATE_TIME_IN_THE_FUTURE_ERROR,
      },
      message:
        INCIDENT_TIME_AND_PLACE_CONSTANTS.EMPLOYER_NOTIFIED_DATE_TIME_IN_THE_FUTURE_ERROR,
    },
  },
};
