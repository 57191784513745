import { Alert, SpaceBetween } from "@amzn/awsui-components-react/polaris";
import { useAppSelector } from "src/redux/hooks";
import { RootState } from "src/redux/store";
import moment from "moment-timezone";
import { UNSUPPORTED_TPA } from "src/forms/constants";
import SimLinkGenerator from "src/components/simLinkGenerator/SimLinkGenerator";
import { SIM_TYPES } from "src/components/simLinkGenerator/types";

const WorkersCompensationAlert = () => {
  // Use the useAppSelector hook to retrieve the tpaMaintenance value from the Redux store
  const { tpaMaintenance } = useAppSelector((state: RootState) => state.app);
  const { workersCompensationTpa } = useAppSelector(
    (state: RootState) => state.forms
  );

  function generateAnchorMaintenanceMessage() {
    // Get timestamp, translate it to Central time and change the time to 1:00:00 AM, then translate
    // to local time and print in Local Time format example: "11:00 PM"
    const localTime = moment()
      .utc()
      .tz("America/Chicago")
      .hour(1)
      .minute(0)
      .second(0)
      .local()
      .format("LT");
    return (
      <span>
        The Third-Party Administrator that this claim will be routed to is
        currently undergoing routine maintenance. Claim submission will be
        re-enabled once maintenance is complete at {localTime}.
      </span>
    );
  }

  // Create a variable tpaMaintenanceAlert that will hold the Alert component
  // If TPA is undergoing maintenance, render the Alert component with the maintenance message
  // Otherwise, don't render alert
  const tpaMaintenanceAlert = (
    <Alert
      type="warning"
      header="Maintenance Notice"
      statusIconAriaLabel="Warning"
      dismissible={false}
      data-testid="tpa-maintenance-alert"
    >
      {generateAnchorMaintenanceMessage()}
    </Alert>
  );
  const unsupportedTpaAlert = (
    <Alert
      type="error"
      header="Unsupported TPA"
      statusIconAriaLabel="Error"
      dismissible={false}
      data-testid="unsupported-tpa-alert"
    >
      <span>
        <span>
          The location where this incident ocurred is not supported by Nucleus.
          Please open a{" "}
        </span>
        <SimLinkGenerator
          target="_blank"
          simType={SIM_TYPES.REPORT_A_BUG}
          ariaLabel="Press this link to open a new tab where you will be able to create a ticket to report the issue with the unsupported location."
        >
          support ticket{" "}
        </SimLinkGenerator>
        <span>for the team to investigate this issue</span>
      </span>
    </Alert>
  );
  const alertsToDisplay = [];
  if (tpaMaintenance) {
    alertsToDisplay.push(tpaMaintenanceAlert);
  }
  if (workersCompensationTpa === UNSUPPORTED_TPA) {
    alertsToDisplay.push(unsupportedTpaAlert);
  }
  return (
    <SpaceBetween direction="vertical" size="s">
      {alertsToDisplay}
    </SpaceBetween>
  );
};
export default WorkersCompensationAlert;
