import {
  FormFieldConfigType,
  SelectFormField,
  TextAreaFormField,
  RadioFormField,
  FormActionButton,
  INPUT_TYPES,
  RADIO_YES_NO,
  MultiselectFormField,
  TEXT_INPUT_TYPES,
  TextInputFormField,
  PhoneFormField,
} from "../../types";
import {
  BODY_PART_OPTIONS,
  SYMPTOM_OPTIONS,
  SEDGWICK_NATURE_OF_INJURY_OPTIONS,
  SEDGWICK_CAUSE_OF_INJURY_OPTIONS,
  REFERRAL_OPTIONS,
  CONCERNS_OPTIONS,
  BODY_SIDE_OPTIONS,
  ANCHOR_INJURY_OPTIONS,
  ANCHOR_ACCIDENT_OPTIONS,
} from "../../options";
import { preProcessFormFields, removeError } from "../../tools";
import {
  INCIDENT_INFORMATION_CONSTANTS,
  PHONE_DESCRIPTION_TEXT,
} from "../../constants";
import { DIFFERENT_COMBINED_VALUE_PRIMARY_ERROR } from "../validations/constants";

const QUESTIONABLE_CLAIM_POPOVER_TEXT =
  "Please select this option and add reasons if you observe inconsistencies in reporting, conflicting evidence or other concerns you would like to share with the adjuster.";
export const formFieldsConfig: FormFieldConfigType[] = [
  {
    name: "allIncidentInformationFields",
    type: INPUT_TYPES.FIELD_GROUP,
    contained: true,
    label: "Incident Details",
    fields: [
      {
        name: INCIDENT_INFORMATION_CONSTANTS.BODY_PART_INFORMATION_0,
        label: "Primary Body Part Information",
        description: "",
        placeholderText: "",
        hasPopover: false,
        type: INPUT_TYPES.FIELD_GROUP,
        contained: true,
        fields: [
          {
            name: INCIDENT_INFORMATION_CONSTANTS.PRIMARY_INJURED_BODY_PART,
            label: "Injured Body Part",
            description: "",
            placeholderText:
              INCIDENT_INFORMATION_CONSTANTS.ADD_BODY_PART_PLACEHOLDER,
            hasPopover: false,
            popoverText: "",
            type: INPUT_TYPES.SELECT,
            options: BODY_PART_OPTIONS,
          } as SelectFormField,
          {
            name: INCIDENT_INFORMATION_CONSTANTS.PRIMARY_INJURED_SIDE,
            label: "Injured Side",
            description: "",
            placeholderText: "Please select the injured side of the body part",
            hasPopover: false,
            type: INPUT_TYPES.SELECT,
            options: BODY_SIDE_OPTIONS,
          } as SelectFormField,
          {
            name: INCIDENT_INFORMATION_CONSTANTS.NATURE_OF_INJURY,
            label: "Nature of Injury/Primary Impact",
            description: "",
            placeholderText: "Please select the nature of the injury",
            hasPopover: false,
            type: INPUT_TYPES.SELECT,
            options: SEDGWICK_NATURE_OF_INJURY_OPTIONS,
          } as SelectFormField,
        ],
      },
      {
        name: INCIDENT_INFORMATION_CONSTANTS.BODY_PART_INFORMATION_1,
        label: "Secondary Body Part Information",
        description: "",
        placeholderText: "",
        hasPopover: false,
        type: INPUT_TYPES.FIELD_GROUP,
        contained: true,
        fields: [
          {
            name: INCIDENT_INFORMATION_CONSTANTS.SECONDARY_INJURED_BODY_PART,
            label: "Secondary Injured Body Part",
            description:
              "If there are more than two body parts to report, please list body parts and relevant details in the Injury/Incident Description section.",
            placeholderText:
              INCIDENT_INFORMATION_CONSTANTS.ADD_BODY_PART_PLACEHOLDER,
            hasPopover: false,
            popoverText: "",
            type: INPUT_TYPES.SELECT,
            options: BODY_PART_OPTIONS,
          } as SelectFormField,
          {
            name: INCIDENT_INFORMATION_CONSTANTS.SECONDARY_INJURED_SIDE,
            label: "Secondary Injured Side",
            description: "",
            placeholderText: "Please select the injured side of the body part",
            hasPopover: false,
            type: INPUT_TYPES.SELECT,
            options: BODY_SIDE_OPTIONS,
          } as SelectFormField,
          {
            name: INCIDENT_INFORMATION_CONSTANTS.SECONDARY_NATURE_OF_INJURY,
            label: "Secondary Nature of Injury",
            description: "",
            placeholderText: "Please select the nature of the injury",
            hasPopover: false,
            type: INPUT_TYPES.SELECT,
            options: ANCHOR_INJURY_OPTIONS,
          } as SelectFormField,
        ],
      },
      {
        name: INCIDENT_INFORMATION_CONSTANTS.ADD_BODY_PART_NAME,
        label: "",
        hasPopover: false,
        popoverText: "",
        type: INPUT_TYPES.BUTTON,
        variant: "link",
        onClick: (formState, updateForm, field, setFocus) => {
          const addBodyPart = field!.state;
          const value =
            addBodyPart.value ===
            INCIDENT_INFORMATION_CONSTANTS.ADD_ADDITIONAL_BODY_PART
              ? INCIDENT_INFORMATION_CONSTANTS.REMOVE_BODY_PART
              : INCIDENT_INFORMATION_CONSTANTS.ADD_ADDITIONAL_BODY_PART;
          const hidden =
            addBodyPart.value !==
            INCIDENT_INFORMATION_CONSTANTS.ADD_ADDITIONAL_BODY_PART;
          const bodyPartInformation_1 =
            formState[INCIDENT_INFORMATION_CONSTANTS.BODY_PART_INFORMATION_1];

          const primaryInjuredBodyPart =
            formState[INCIDENT_INFORMATION_CONSTANTS.PRIMARY_INJURED_BODY_PART];
          const natureOfInjury =
            formState[INCIDENT_INFORMATION_CONSTANTS.NATURE_OF_INJURY];

          // If the user hides the secondary body part we need to remove the error for the different
          // combined values since it does not make sense to have that error show when only
          // one body part is shown
          if (hidden) {
            removeError(
              DIFFERENT_COMBINED_VALUE_PRIMARY_ERROR,
              primaryInjuredBodyPart.errors
            );
            removeError(
              DIFFERENT_COMBINED_VALUE_PRIMARY_ERROR,
              natureOfInjury.errors
            );
          }

          const secondaryInjuredBodyPart =
            formState[
              INCIDENT_INFORMATION_CONSTANTS.SECONDARY_INJURED_BODY_PART
            ];
          const secondaryNatureOfInjury =
            formState[
              INCIDENT_INFORMATION_CONSTANTS.SECONDARY_NATURE_OF_INJURY
            ];
          const secondaryInjuredSide =
            formState[INCIDENT_INFORMATION_CONSTANTS.SECONDARY_INJURED_SIDE];

          updateForm!({
            [INCIDENT_INFORMATION_CONSTANTS.ADD_BODY_PART_NAME]: {
              ...addBodyPart,
              value,
            },
            [INCIDENT_INFORMATION_CONSTANTS.BODY_PART_INFORMATION_1]: {
              ...bodyPartInformation_1,
              hidden,
            },
            [INCIDENT_INFORMATION_CONSTANTS.SECONDARY_INJURED_BODY_PART]: {
              ...secondaryInjuredBodyPart,
              errors: hidden ? [] : secondaryInjuredBodyPart.errors,
            },
            [INCIDENT_INFORMATION_CONSTANTS.SECONDARY_NATURE_OF_INJURY]: {
              ...secondaryNatureOfInjury,
              errors: hidden ? [] : secondaryNatureOfInjury.errors,
            },
            [INCIDENT_INFORMATION_CONSTANTS.SECONDARY_INJURED_SIDE]: {
              ...secondaryInjuredSide,
              hidden,
            },
            [INCIDENT_INFORMATION_CONSTANTS.PRIMARY_INJURED_BODY_PART]: {
              ...primaryInjuredBodyPart,
            },
            [INCIDENT_INFORMATION_CONSTANTS.NATURE_OF_INJURY]: {
              ...natureOfInjury,
            },
          });
          const focusOn = hidden
            ? INCIDENT_INFORMATION_CONSTANTS.NATURE_OF_INJURY
            : INCIDENT_INFORMATION_CONSTANTS.SECONDARY_INJURED_BODY_PART;
          setFocus(focusOn);
        },
        placeholderText: "",
      } as FormActionButton,
      {
        name: INCIDENT_INFORMATION_CONSTANTS.CAUSE_OF_INJURY,
        label: "Cause of Injury/Secondary Impact",
        description: "",
        placeholderText: "Please select the cause of the injury",
        hasPopover: false,
        type: INPUT_TYPES.SELECT,
        options: SEDGWICK_CAUSE_OF_INJURY_OPTIONS,
      } as SelectFormField,
      {
        name: INCIDENT_INFORMATION_CONSTANTS.ACCIDENT_TYPE,
        label: "Accident Type",
        description: "",
        placeholderText: "Please select the type of accident that occurred",
        hasPopover: false,
        type: INPUT_TYPES.SELECT,
        options: ANCHOR_ACCIDENT_OPTIONS,
      } as SelectFormField,
      {
        name: INCIDENT_INFORMATION_CONSTANTS.SOURCE_OF_INJURY,
        label: "What was the source of the injury?",
        description:
          "Please note the source of the injury to the associate. This is the mechanism that caused the injury. For example, 'heavy box' or 'cart'.",
        placeholderText: "Please type the source of the injury",
        hasPopover: false,
        type: INPUT_TYPES.TEXT,
        textInputType: TEXT_INPUT_TYPES.DEFAULT,
      } as TextInputFormField,
      {
        name: INCIDENT_INFORMATION_CONSTANTS.SYMPTOMS_LIST,
        label: "Associate Reported Signs/Symptoms",
        description: "",
        placeholderText: "Please select the symptoms reported by the associate",
        hasPopover: false,
        type: INPUT_TYPES.MULTISELECT,
        options: SYMPTOM_OPTIONS,
      } as MultiselectFormField,
      {
        name: INCIDENT_INFORMATION_CONSTANTS.LOSS_DESCRIPTION,
        label: "Injury/Incident Description",
        description:
          "Describe the specifics about how the incident or event occurred.",
        placeholderText:
          INCIDENT_INFORMATION_CONSTANTS.INCIDENT_DESCRIPTION_PLACEHOLDER,
        hasPopover: true,
        popoverText:
          INCIDENT_INFORMATION_CONSTANTS.INCIDENT_DESCRIPTION_PLACEHOLDER,
        type: INPUT_TYPES.TEXTAREA,
        constraintText: "",
      } as TextAreaFormField,
      {
        name: INCIDENT_INFORMATION_CONSTANTS.REFERRAL_TYPE,
        label: "Referral Type",
        description: "",
        placeholderText:
          "Please select how the associate is being referred to a provider",
        hasPopover: false,
        type: INPUT_TYPES.SELECT,
        options: REFERRAL_OPTIONS,
      } as SelectFormField,
      {
        name: INCIDENT_INFORMATION_CONSTANTS.QUESTIONABLE_CLAIM,
        label: "Do you have any concerns you would like to share?",
        description: "",
        placeholderText: "hh:mm",
        hasPopover: true,
        popoverText: QUESTIONABLE_CLAIM_POPOVER_TEXT,
        type: INPUT_TYPES.RADIO,
        options: RADIO_YES_NO,
        onChange: (
          { detail }: { detail: any },
          formState,
          updateForm,
          field
        ) => {
          const hidden = detail.value === "false" ? true : false;
          const questionableClaimGroup =
            formState[INCIDENT_INFORMATION_CONSTANTS.QUESTIONABLE_CLAIM_GROUP];
          const questionableClaim = field.state;
          updateForm({
            [INCIDENT_INFORMATION_CONSTANTS.QUESTIONABLE_CLAIM_GROUP]: {
              ...questionableClaimGroup,
              hidden,
            },
            [field.config.name]: {
              ...questionableClaim,
              value: detail.value,
              errors: [],
            },
          });
        },
      } as RadioFormField,
      {
        name: INCIDENT_INFORMATION_CONSTANTS.QUESTIONABLE_CLAIM_GROUP,
        label: "Concerns",
        description: "",
        placeholderText: "",
        hasPopover: false,
        type: INPUT_TYPES.FIELD_GROUP,
        contained: true,
        fields: [
          {
            name: INCIDENT_INFORMATION_CONSTANTS.QUESTIONABLE_CLAIM_REASONS,
            label: "Concerns",
            description: "",
            placeholderText: "Please select your concerns",
            hasPopover: false,
            popoverText: "",
            type: INPUT_TYPES.MULTISELECT,
            options: CONCERNS_OPTIONS,
          } as MultiselectFormField,
          {
            name: INCIDENT_INFORMATION_CONSTANTS.QUESTIONABLE_CLAIM_ADDITIONAL_REASON,
            label: "Additional Information",
            description: "",
            placeholderText: "Write any other concern(s) not on the list",
            hasPopover: false,
            type: INPUT_TYPES.TEXTAREA,
            constraintText: "",
          } as TextAreaFormField,
        ],
      },
      {
        name: INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_AVAILABLE,
        label: "Was there a witness to this incident?",
        placeholderText: "",
        hasPopover: false,
        type: INPUT_TYPES.RADIO,
        options: RADIO_YES_NO,
        onChange: (
          { detail }: { detail: any },
          formState,
          updateForm,
          field
        ) => {
          const hidden = detail.value === "true" ? false : true;

          const witnessInformation_0 =
            formState[INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_0];
          const witnessInformation_1 =
            formState[INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_1];
          const addWitness =
            formState[INCIDENT_INFORMATION_CONSTANTS.ADD_WITNESS];
          const witnessInformationAvailable = { ...field.state };
          const groupHide =
            detail.value === "false" ? true : witnessInformation_1.hidden;
          const addWitnessValue = groupHide
            ? INCIDENT_INFORMATION_CONSTANTS.ADD_ADDITIONAL_WITNESS
            : "Remove Witness";
          updateForm({
            [INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_0]: {
              ...witnessInformation_0,
              hidden,
            },
            [INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_1]: {
              ...witnessInformation_1,
              hidden: groupHide,
            },
            [INCIDENT_INFORMATION_CONSTANTS.ADD_WITNESS]: {
              ...addWitness,
              hidden,
              value: addWitnessValue,
            },
            [INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_AVAILABLE]: {
              ...witnessInformationAvailable,
              value: detail.value,
            },
          });
        },
      } as RadioFormField,
      {
        name: INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_0,
        label: "Witness Information",
        description: "",
        placeholderText: "",
        hasPopover: false,
        type: INPUT_TYPES.FIELD_GROUP,
        contained: true,
        fields: [
          {
            name: "firstName",
            uniqueName:
              INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_0_FIRST_NAME,
            label: "First Name",
            description: "",
            placeholderText: "",
            hasPopover: false,
            type: INPUT_TYPES.TEXT,
            textInputType: TEXT_INPUT_TYPES.DEFAULT,
          } as TextInputFormField,
          {
            name: "lastName",
            uniqueName:
              INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_0_LAST_NAME,
            label: "Last Name",
            description: "",
            placeholderText: "",
            hasPopover: false,
            type: INPUT_TYPES.TEXT,
            textInputType: TEXT_INPUT_TYPES.DEFAULT,
          } as TextInputFormField,
          {
            name: "alias",
            uniqueName:
              INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_0_ALIAS,
            label: "Alias/Login",
            description: "",
            placeholderText: "",
            hasPopover: false,
            type: INPUT_TYPES.TEXT,
            textInputType: TEXT_INPUT_TYPES.DEFAULT,
          } as TextInputFormField,
          {
            name: "email",
            uniqueName:
              INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_0_EMAIL,
            label: "Personal Email",
            description: "",
            placeholderText: "",
            hasPopover: false,
            type: INPUT_TYPES.TEXT,
            textInputType: TEXT_INPUT_TYPES.DEFAULT,
          } as TextInputFormField,
          {
            name: "phone",
            uniqueName:
              INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_0_PHONE,
            label: "Phone",
            description: PHONE_DESCRIPTION_TEXT,
            placeholderText: "",
            hasPopover: false,
            type: INPUT_TYPES.PHONE,
            textInputType: TEXT_INPUT_TYPES.DEFAULT,
            constraintText: "",
          } as PhoneFormField,
        ],
      },

      {
        name: INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_1,
        label: "Additional Witness Information",
        description: "",
        placeholderText: "",
        hasPopover: false,
        type: INPUT_TYPES.FIELD_GROUP,
        contained: true,
        fields: [
          {
            name: "firstName",
            uniqueName:
              INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_1_FIRST_NAME,
            label: "First Name",
            description: "",
            placeholderText: "",
            hasPopover: false,
            type: INPUT_TYPES.TEXT,
            textInputType: TEXT_INPUT_TYPES.DEFAULT,
          } as TextInputFormField,
          {
            name: "lastName",
            uniqueName:
              INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_1_LAST_NAME,
            label: "Last Name",
            description: "",
            placeholderText: "",
            hasPopover: false,
            type: INPUT_TYPES.TEXT,
            textInputType: TEXT_INPUT_TYPES.DEFAULT,
          } as TextInputFormField,
          {
            name: "alias",
            uniqueName:
              INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_1_ALIAS,
            label: "Alias/Login",
            description: "",
            placeholderText: "",
            hasPopover: false,
            type: INPUT_TYPES.TEXT,
            textInputType: TEXT_INPUT_TYPES.DEFAULT,
          } as TextInputFormField,
          {
            name: "email",
            uniqueName:
              INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_1_EMAIL,
            label: "Personal Email",
            description: "",
            placeholderText: "",
            hasPopover: false,
            type: INPUT_TYPES.TEXT,
            textInputType: TEXT_INPUT_TYPES.DEFAULT,
          } as TextInputFormField,
          {
            name: "phone",
            uniqueName:
              INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_1_PHONE,
            label: "Phone",
            description: PHONE_DESCRIPTION_TEXT,
            placeholderText: "",
            hasPopover: false,
            type: INPUT_TYPES.PHONE,
            textInputType: TEXT_INPUT_TYPES.DEFAULT,
            constraintText: "",
          } as PhoneFormField,
        ],
      },
      {
        name: INCIDENT_INFORMATION_CONSTANTS.ADD_WITNESS,
        label: "",
        hasPopover: false,
        popoverText: "",
        type: INPUT_TYPES.BUTTON,
        variant: "link",
        onClick: (formState, updateForm, field, setFocus) => {
          const addWitness = { ...field!.state };
          const value =
            addWitness.value ===
            INCIDENT_INFORMATION_CONSTANTS.ADD_ADDITIONAL_WITNESS
              ? INCIDENT_INFORMATION_CONSTANTS.REMOVE_ADDITIONAL_WITNESS
              : INCIDENT_INFORMATION_CONSTANTS.ADD_ADDITIONAL_WITNESS;
          const hidden =
            addWitness.value ===
            INCIDENT_INFORMATION_CONSTANTS.ADD_ADDITIONAL_WITNESS
              ? false
              : true;

          const witnessInformation_1 =
            formState[INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_1];
          updateForm!({
            [INCIDENT_INFORMATION_CONSTANTS.ADD_WITNESS]: {
              ...addWitness,
              value,
            },
            [INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_1]: {
              ...witnessInformation_1,
              hidden,
            },
          });
          const focusOn = hidden
            ? INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_AVAILABLE
            : "witnessInformation_1_firstName";
          setFocus(focusOn);
        },
        placeholderText: "",
      } as FormActionButton,
    ],
  },
];

export const formFieldsConfigProcessed = preProcessFormFields(formFieldsConfig);
