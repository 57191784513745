import React, { useState, useLayoutEffect, useEffect } from "react";
import WizardWrapper from "../../components/wizard/WizardWrapper";

import * as AssociateInformation from "../../forms/WorkersCompensation/AssociateInformation";
import * as IncidentTimeAndPlace from "../../forms/WorkersCompensation/IncidentTimeAndPlace";

import * as IncidentInformation from "../../forms/WorkersCompensation/IncidentInformation";
import * as PhysicianInformation from "../../forms/WorkersCompensation/PhysicianInformation";
import * as FileAttachments from "../../forms/WorkersCompensation/FileAttachments";

import FormContent from "../../forms/FormContent";
import {
  FormFieldConfigType,
  FormStateType,
  FormValidationType,
} from "../../forms/types";
import {
  resetUpdatedForm,
  saveForm,
  setValueInFormsState,
} from "../../forms/formsSlice";

import ClaimSubmitted from "../../views/ClaimSubmitted";
import { buildWcCreateClaimInput } from "./model/ClaimIntakeModel";

import {
  Form,
  TextContent,
  Box,
  WizardProps,
} from "@amzn/awsui-components-react/polaris";
import { CreateWcClaimCommandInput } from "@amzn/ttechclaimintakeservice-client";

import ResetFormModal from "../../components/reset-form-modal/ResetFormModal";
import moment from "moment-timezone";

import { MetricsPublisher } from "../../metrics";

import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import {
  createWcClaim,
  requestAppReset,
  setValueInAppState,
} from "../appSlice";
import {
  FORM_PAGE_ERROR_MESSAGE,
  MAX_STEPS,
  STEP_NAMES,
  DUPLICATE_MODAL_TITLE,
} from "../constants";
import { useAppSelector } from "src/redux/hooks";

import ReviewPage from "../../forms/reviewPage/ReviewPage";
import { getSite } from "src/sites/sitesSlice";
import {
  ANCHOR,
  ANCHOR_STATES,
  ASSOCIATE_INFORMATION_CONSTANTS,
  FILE_ATTACHMENTS_CONSTANTS,
  HELMSMAN,
  HELMSMAN_INCIDENT_DATE_CUTOFF,
  HELMSMAN_STATES,
  INCIDENT_INFORMATION_CONSTANTS,
  INCIDENT_TIME_AND_PLACE_CONSTANTS,
  PHYSICIAN_INFORMATION_CONSTANTS,
  REVIEW_PAGE_CONSTANTS,
  SEDGWICK,
  SEDGWICK_STATES,
  UNSUPPORTED_TPA,
} from "src/forms/constants";

import type { ResetFormModalContents } from "../types";
import { cloneDeep } from "lodash";
import { TPA_FORM_OVERRIDES } from "src/forms/WorkersCompensation/tpaOverrides";
import { getFieldConfig } from "src/forms/tools";
import { SiteTypes } from "src/sites/constants";
import { isDevEnvironment } from "src/environmentInfo/EnvironmentInfo";
import WorkersCompensationAlert from "./WorkersCompensationAlert";

const DEFAULT_FORM_CONFIGS = {
  [ASSOCIATE_INFORMATION_CONSTANTS.FORM_NAME]: cloneDeep(
    AssociateInformation.formConfig
  ),
  [INCIDENT_TIME_AND_PLACE_CONSTANTS.FORM_NAME]: cloneDeep(
    IncidentTimeAndPlace.formConfig
  ),
  [INCIDENT_INFORMATION_CONSTANTS.FORM_NAME]: cloneDeep(
    IncidentInformation.formConfig
  ),
  [PHYSICIAN_INFORMATION_CONSTANTS.FORM_NAME]: cloneDeep(
    PhysicianInformation.formConfig
  ),
  [FILE_ATTACHMENTS_CONSTANTS.FORM_NAME]: cloneDeep(FileAttachments.formConfig),
};
const DEFAULT_FORM_VALIDATIONS = {
  [ASSOCIATE_INFORMATION_CONSTANTS.FORM_NAME]: cloneDeep(
    AssociateInformation.formValidation
  ),
  [INCIDENT_TIME_AND_PLACE_CONSTANTS.FORM_NAME]: cloneDeep(
    IncidentTimeAndPlace.formValidation
  ),
  [INCIDENT_INFORMATION_CONSTANTS.FORM_NAME]: cloneDeep(
    IncidentInformation.formValidation
  ),
  [PHYSICIAN_INFORMATION_CONSTANTS.FORM_NAME]: cloneDeep(
    PhysicianInformation.formValidation
  ),
  [FILE_ATTACHMENTS_CONSTANTS.FORM_NAME]: cloneDeep(
    FileAttachments.formValidation
  ),
};

function WorkersCompensationApp() {
  const dispatch = useDispatch<AppDispatch>();

  //Redux Forms state
  const {
    errors,
    allFormStates,
    validating,
    updatedForm,
    employeeDetailsRetrieved,
    workersCompensationTpa,
    tpaChangeDetected,
    previousWorkersCompensationTpa,
  } = useAppSelector((state: RootState) => state.forms);
  //Redux App state
  const {
    duplicateClaim,
    duplicateClaimChecked,
    triggerOpenResetFormModal,
    remoteResetFormModalContents,
    amazonClaimReferenceId,
  } = useAppSelector((state: RootState) => state.app);

  //Redux Auth state
  const { user } = useAppSelector((state: RootState) => state.auth);

  //Redux Wizard state
  const { goingBack } = useAppSelector((state: RootState) => state.wizard);
  //Redux Sites state
  const { incidentSite, associateHomeSite } = useAppSelector(
    (state: RootState) => state.sites
  );
  //Local state variables
  const [renderSubmitScreen, setRenderSubmitScreen] = useState(false);

  const [currentStep, setCurrentStep] = useState(0);
  const [formattedFormErrors, setFormattedFormErrors] = useState(null);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [resetFormModalOpen, setResetFormModalOpen] = useState(false);
  const [resetFormModalContents, setResetFormModalContents] =
    useState<ResetFormModalContents>({
      title: "",
      description: null,
      confirm: "",
      cancel: "",
    });
  const [claimModel, setClaimModel] =
    useState<CreateWcClaimCommandInput | null>(null);

  const [localFormConfigs, setLocalFormConfigs] =
    useState(DEFAULT_FORM_CONFIGS);
  const [localFormValidations, setLocalFormValidations] = useState(
    DEFAULT_FORM_VALIDATIONS
  );

  const [maintenanceTimeWindowActive, setMaintenanceTimeWindowActive] =
    useState(false);
  const incidentDate =
    allFormStates[INCIDENT_TIME_AND_PLACE_CONSTANTS.FORM_NAME].incidentDate;
  function createFinalForm(): CreateWcClaimCommandInput {
    let formState = {};
    let formConfig: FormFieldConfigType[] = [];
    for (const formName in localFormConfigs) {
      formState = { ...formState, ...allFormStates[formName] };
      formConfig = [
        ...formConfig,
        ...localFormConfigs[formName as keyof typeof localFormConfigs],
      ];
    }
    // We need to add these fields to the model builder to avoid getting
    // Redux store values outside of React components
    const additionalFields = {
      incidentSite,
      associateHomeSite,
      user,
      workersCompensationTpa,
      amazonClaimReferenceId,
    };
    return buildWcCreateClaimInput(formState, formConfig, additionalFields);
  }
  /**
   * useEffect that runs every time component mounts, used to set initial values.
   */
  useEffect(() => {
    setFormattedFormErrors(null);
  }, []);

  /**
   * useLayoutEffect that runs when the form is being validated and to check if we can submit the form
   * and display errors
   */
  useLayoutEffect(() => {
    if (validating) {
      return;
    }
    if (!errors.length && submitClicked) {
      submitForm();
      return;
    }
    setSubmitClicked(false);
    if (errors.length) {
      const errorText: any = (
        <div role="alert" title="form-errors" tabIndex={-1}>
          <TextContent>
            <span className="form-page-error">{FORM_PAGE_ERROR_MESSAGE}</span>
          </TextContent>
        </div>
      );
      if (!goingBack) {
        dispatch(resetUpdatedForm());
        setFormattedFormErrors(errorText);
      }
    }
  }, [errors, validating, submitClicked]);

  /**
   * useEffect that checks if the error banner is present and focuses into it for accessibility purposes.
   */
  useEffect(() => {
    if (formattedFormErrors) {
      const errorElement = document.querySelector('[title="form-errors"]');
      if (errorElement) {
        (errorElement as HTMLElement).tabIndex = 0;
        (errorElement as HTMLElement).focus();
        window.scrollTo(0, document.body.scrollHeight);
      }
    }
  }, [formattedFormErrors]);
  /**
   * useEffect that runs whenever a duplicateClaim has been found
   */
  useEffect(() => {
    if (duplicateClaim && duplicateClaimChecked) {
      const {
        claimNumber,
        incidentTimestamp,
        primaryInjuredBodyPartDescription,
        secondaryInjuredBodyPartDescription,
      } = duplicateClaim;
      const incidentTime = moment
        .unix(incidentTimestamp)
        .format("MMMM Do YYYY, h:mm:ss a");
      const description = (
        <TextContent>
          <Box textAlign="left">
            <div className="modal-text">
              <div>
                A claim has already been submitted for this employee with the
                following information:
                <ul>
                  <li>Claim Number: {claimNumber}</li>
                  <li>Date and Time: {incidentTime}</li>
                  <li>
                    Injured Body Parts:
                    <ul>
                      {primaryInjuredBodyPartDescription ? (
                        <li>{primaryInjuredBodyPartDescription}</li>
                      ) : null}
                      {secondaryInjuredBodyPartDescription ? (
                        <li>{secondaryInjuredBodyPartDescription}</li>
                      ) : null}
                    </ul>
                  </li>
                </ul>
              </div>
              <div>
                <b>Please verify this is not a duplicate submission.</b>
              </div>
              <br />
              <div>
                Would you like to continue to file a new claim for the same
                date?
              </div>
            </div>
          </Box>
        </TextContent>
      );
      const metricsPublisher = new MetricsPublisher("duplicateClaimAttempt");
      metricsPublisher.publishDuplicateClaimAttemptMetric(user.id);

      setResetFormModalContents({
        title: DUPLICATE_MODAL_TITLE,
        description,
        cancel: "Yes",
        confirm: "No, Reset Form",
      });
      setResetFormModalOpen(true);
    } else {
      setResetFormModalOpen(false);
    }
  }, [duplicateClaim, duplicateClaimChecked]);

  /**
   * useEffect that checks if form has been updated to remove the error banner.
   */
  useEffect(() => {
    if (updatedForm && formattedFormErrors) {
      setFormattedFormErrors(null);
    }
  }, [updatedForm]);

  /**
   * useEffect that triggers the reset modal to open
   */
  useEffect(() => {
    if (triggerOpenResetFormModal) {
      setResetFormModalContents(remoteResetFormModalContents);
      setResetFormModalOpen(true);
      dispatch(
        setValueInAppState({ key: "triggerOpenResetFormModal", value: false })
      );
    }
  }, [triggerOpenResetFormModal]);

  /**
   * This function determines the Third Party Administrator (TPA)
   * based on the incident site and date
   */
  const getTpaFromSiteAndDate = () => {
    if (!incidentSite?.state || !incidentDate.value) {
      return null;
    }
    const incidentDateObject = new Date(incidentDate.value);
    if (
      SEDGWICK_STATES.includes(incidentSite.state) ||
      (incidentDateObject.getTime() < HELMSMAN_INCIDENT_DATE_CUTOFF.getTime() &&
        HELMSMAN_STATES.includes(incidentSite.state))
    ) {
      return SEDGWICK;
    }
    if (ANCHOR_STATES.includes(incidentSite.state)) {
      return ANCHOR;
    }
    if (
      incidentDateObject.getTime() >= HELMSMAN_INCIDENT_DATE_CUTOFF.getTime() &&
      HELMSMAN_STATES.includes(incidentSite.state)
    ) {
      return HELMSMAN;
    }
    return UNSUPPORTED_TPA;
  };
  /**
   * useLayoutEffect hook to set TPA when incidentDate or incidentSite values change before render
   */
  useLayoutEffect(() => {
    if (!incidentSite?.state) {
      return;
    }
    const detectedTpa = getTpaFromSiteAndDate();
    if (!detectedTpa) {
      return;
    }
    dispatch(
      setValueInFormsState({
        key: "workersCompensationTpa",
        value: detectedTpa,
      })
    );
    dispatch(setValueInFormsState({ key: "tpaChangeDetected", value: true }));
  }, [incidentSite, incidentDate.value]);

  /**
   * useEffect hook to set site information when retrieving user's information
   */
  useEffect(() => {
    if (employeeDetailsRetrieved) {
      const associateHomeSiteCode =
        allFormStates[ASSOCIATE_INFORMATION_CONSTANTS.FORM_NAME]?.[
          ASSOCIATE_INFORMATION_CONSTANTS.ASSOCIATE_HOME_SITE_CODE
        ];
      if (associateHomeSiteCode && !incidentSite) {
        dispatch(
          getSite({
            code: associateHomeSiteCode.value,
            type: SiteTypes.ALL_CLAIM_SITES,
          })
        );
      }
    }
  }, [employeeDetailsRetrieved]);
  /**
   * Change formConfig and formValidation if TPA is updated
   */

  const applyConfigOverrides = (formConfig: FormFieldConfigType[]) => {
    const formOverride = TPA_FORM_OVERRIDES[workersCompensationTpa];
    for (const fieldName in formOverride.config) {
      const fieldToUpdate = getFieldConfig(fieldName, formConfig);
      if (fieldToUpdate) {
        Object.assign(fieldToUpdate, {
          ...fieldToUpdate,
          ...formOverride.config[fieldName],
        });
      }
    }
    return formConfig;
  };

  const applyValidationOverrides = (formValidation: FormValidationType) => {
    const formOverride = TPA_FORM_OVERRIDES[workersCompensationTpa];
    for (const fieldName in formOverride.validation) {
      const fieldToUpdate =
        formValidation[fieldName as keyof typeof formValidation];
      if (fieldToUpdate) {
        Object.assign(fieldToUpdate, {
          ...fieldToUpdate,
          ...formOverride.validation[fieldName],
        });
      }
    }
    return formValidation;
  };

  const applyStateOverrides = (formState: FormStateType) => {
    const formOverride = TPA_FORM_OVERRIDES[workersCompensationTpa];
    for (const fieldName in formOverride.state) {
      const fieldToUpdate = formState[fieldName as keyof typeof formState];
      if (fieldToUpdate) {
        Object.assign(fieldToUpdate, {
          ...fieldToUpdate,
          ...formOverride.state[fieldName],
        });
      }
    }
    return formState;
  };

  /**
   * useLayoutEffect hook to apply form overrides before render if TPA was changed
   */
  useLayoutEffect(() => {
    if (!tpaChangeDetected) return;
    dispatch(setValueInFormsState({ key: "tpaChangeDetected", value: false }));
    // Form cannot be submitted if the TPA is not supported
    if (workersCompensationTpa === UNSUPPORTED_TPA) {
      return;
    }

    const tempLocalFormConfigs = cloneDeep(DEFAULT_FORM_CONFIGS);
    const tempLocalFormValidations = cloneDeep(DEFAULT_FORM_VALIDATIONS);
    const tempAllFormStates = cloneDeep(allFormStates);

    Object.values(tempLocalFormConfigs).forEach((config) => {
      applyConfigOverrides(config);
    });
    Object.values(tempLocalFormValidations).forEach((formValidation) => {
      applyValidationOverrides(formValidation);
    });

    /**
     * We only update the form state if the previous TPA is different from the new one
     * this ensures we can keep user changes when reloading the page like hiding or showing
     * different fields. We do need to update config and validation values since those are
     * not persistent on reload.
     */
    if (previousWorkersCompensationTpa !== workersCompensationTpa) {
      for (const formName in tempAllFormStates) {
        if (formName === ASSOCIATE_INFORMATION_CONSTANTS.FORM_NAME) {
          continue;
        }
        const currentFormState =
          tempAllFormStates[formName as keyof typeof tempAllFormStates];
        applyStateOverrides(currentFormState);
        dispatch(saveForm({ formName, formState: currentFormState }));
      }
    }
    dispatch(
      setValueInFormsState({
        key: "previousWorkersCompensationTpa",
        value: workersCompensationTpa,
      })
    );
    setLocalFormConfigs(tempLocalFormConfigs);
    setLocalFormValidations(tempLocalFormValidations);
  }, [
    workersCompensationTpa,
    tpaChangeDetected,
    previousWorkersCompensationTpa,
  ]);

  /**
   * Checks if the current time in Central Time is within the maintenance window (12:00 AM - 1:00 AM Central Time)
   * and updates the 'maintenanceTimeWindowActive' value accordingly.
   */
  function checkMaintenanceWindow() {
    const centralDateTime = moment().utc().tz("America/Chicago");
    // Check if the current hour is 0 (12:00 AM)
    if (centralDateTime.hour() === 0) {
      if (!maintenanceTimeWindowActive) {
        setMaintenanceTimeWindowActive(true);
      }
    } else {
      if (maintenanceTimeWindowActive) {
        setMaintenanceTimeWindowActive(false);
      }
    }
  }
  /**
   * Set an interval for every minute to check when the TPA maintenance is active
   */
  useEffect(() => {
    checkMaintenanceWindow();
    const interval = setInterval(checkMaintenanceWindow, 60000);
    return () => {
      // Clear interval when component dismounts
      clearInterval(interval);
    };
  }, [checkMaintenanceWindow]);

  /**
   * Check if maintenance window is active and current TPA is Anchor, if so, set
   * tpaMaintenance to true which will be read by the WorkersCompensationAlert component
   * to display the maintenance alert.
   */
  useEffect(() => {
    if (maintenanceTimeWindowActive && workersCompensationTpa === ANCHOR) {
      dispatch(setValueInAppState({ key: "tpaMaintenance", value: true }));
    }
    if (!maintenanceTimeWindowActive) {
      dispatch(setValueInAppState({ key: "tpaMaintenance", value: false }));
    }
  }, [maintenanceTimeWindowActive, workersCompensationTpa]);

  function submitForm() {
    const createWcClaimInput: CreateWcClaimCommandInput = createFinalForm();

    dispatch(
      setValueInAppState({ key: "submitScreenStatus", value: "loading" })
    );
    dispatch(createWcClaim({ createWcClaimInput }));

    setFormattedFormErrors(null);
    setSubmitClicked(false);
    setRenderSubmitScreen(true);
    setClaimModel(createWcClaimInput);
  }
  function onSubmit() {
    setSubmitClicked(true);
  }
  function onBackToEdit() {
    setCurrentStep(MAX_STEPS - 1);
    setRenderSubmitScreen(false);
  }
  function onResetForm() {
    const description = (
      <TextContent>
        <Box textAlign="left">
          <div className="modal-text">
            <div>Are you sure you want to reset the form?</div>
            <div>
              All progress will be lost and all of the form information will be
              deleted.
            </div>
          </div>
        </Box>
      </TextContent>
    );
    setResetFormModalContents({
      title: "Reset Form",
      description,
      cancel: "Cancel",
      confirm: "Reset Form",
    });

    setResetFormModalOpen(true);
  }
  function onStartNewClaim() {
    dispatch(requestAppReset());
  }
  function onPrevious() {
    if (formattedFormErrors) setFormattedFormErrors(null);
  }
  function onSkipTo(goingBack: boolean) {
    if (goingBack) {
      if (formattedFormErrors) setFormattedFormErrors(null);
    }
  }

  if (renderSubmitScreen) {
    return (
      <ClaimSubmitted
        key="claim submitted"
        onBackToEdit={onBackToEdit}
        onStartNewClaim={onStartNewClaim}
        claimModel={claimModel}
      />
    );
  }
  const steps: WizardProps.Step[] = [
    {
      title: AssociateInformation.formHeader,
      description: AssociateInformation.formDescription,
      content: (
        <Form
          errorText={formattedFormErrors}
          errorIconAriaLabel={FORM_PAGE_ERROR_MESSAGE}
        >
          <FormContent
            formName={AssociateInformation.formName}
            formConfig={localFormConfigs[AssociateInformation.formName]}
            formValidation={localFormValidations[AssociateInformation.formName]}
            data-testid="associate-information-form"
            key="associate-information-form"
          />
        </Form>
      ),
      isOptional: isDevEnvironment(),
    },

    {
      title: IncidentTimeAndPlace.formHeader,
      description: IncidentTimeAndPlace.formDescription,
      content: (
        <Form
          errorText={formattedFormErrors}
          errorIconAriaLabel={FORM_PAGE_ERROR_MESSAGE}
        >
          <FormContent
            formName={IncidentTimeAndPlace.formName}
            formConfig={localFormConfigs[IncidentTimeAndPlace.formName]}
            formValidation={localFormValidations[IncidentTimeAndPlace.formName]}
            data-testid="incident-time-and-place-form"
            key="incident-time-and-place-form"
          />
        </Form>
      ),
      isOptional: isDevEnvironment(),
    },
    {
      title: IncidentInformation.formHeader,
      description: IncidentInformation.formDescription,
      content: (
        <Form
          errorText={formattedFormErrors}
          errorIconAriaLabel={FORM_PAGE_ERROR_MESSAGE}
        >
          <FormContent
            formName={IncidentInformation.formName}
            formConfig={localFormConfigs[IncidentInformation.formName]}
            formValidation={localFormValidations[IncidentInformation.formName]}
            data-testid="incident-information-form"
            key="incident-information-form"
          />
        </Form>
      ),
      isOptional: isDevEnvironment(),
    },
    {
      title: PhysicianInformation.formHeader,
      description: PhysicianInformation.formDescription,
      content: (
        <Form
          errorText={formattedFormErrors}
          errorIconAriaLabel={FORM_PAGE_ERROR_MESSAGE}
        >
          <FormContent
            formName={PhysicianInformation.formName}
            formConfig={localFormConfigs[PhysicianInformation.formName]}
            formValidation={localFormValidations[PhysicianInformation.formName]}
            data-testid="physician-information-form"
            key="physician-information-form"
          />
        </Form>
      ),
      isOptional: isDevEnvironment(),
    },
    {
      title: FileAttachments.formHeader,
      description: FileAttachments.formDescription,
      content: (
        <Form
          errorText={formattedFormErrors}
          errorIconAriaLabel={FORM_PAGE_ERROR_MESSAGE}
        >
          <FormContent
            formName={FileAttachments.formName}
            formConfig={localFormConfigs[FileAttachments.formName]}
            formValidation={localFormValidations[FileAttachments.formName]}
            data-testid="file-attachments-form"
            key="file-attachments-form"
          />
        </Form>
      ),
      isOptional: isDevEnvironment(),
    },
    {
      title: REVIEW_PAGE_CONSTANTS.FORM_NAME,
      description: REVIEW_PAGE_CONSTANTS.FORM_DESCRIPTION,
      content: <ReviewPage allFormConfigs={localFormConfigs}></ReviewPage>,
      isOptional: isDevEnvironment(),
    },
  ];
  return (
    <React.Fragment>
      <ResetFormModal
        setModalOpen={setResetFormModalOpen}
        onResetForm={onStartNewClaim}
        isModalOpen={resetFormModalOpen}
        modalTitle={resetFormModalContents.title}
        modalDescription={resetFormModalContents.description}
        cancelButtonText={resetFormModalContents.cancel}
        confirmButtonText={resetFormModalContents.confirm}
      />
      <WorkersCompensationAlert />
      <WizardWrapper
        onSubmit={onSubmit}
        onSkipTo={onSkipTo}
        onPrevious={onPrevious}
        onReset={onResetForm}
        currentStep={currentStep}
        stepNames={STEP_NAMES}
        allFormValidations={localFormValidations}
        steps={steps}
      />
    </React.Fragment>
  );
}
export default WorkersCompensationApp;
