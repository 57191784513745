import {
  Container,
  SpaceBetween,
  ColumnLayout,
  Button,
  ExpandableSection,
  Header,
  Box,
  Form,
} from "@amzn/awsui-components-react/polaris";
import React, { useLayoutEffect } from "react";
import { setSkipToStep } from "src/components/wizard/wizardSlice";
import * as AssociateInformation from "../WorkersCompensation/AssociateInformation";
import * as IncidentTimeAndPlace from "../WorkersCompensation/IncidentTimeAndPlace";
import * as IncidentInformation from "../WorkersCompensation/IncidentInformation";
import * as PhysicianInformation from "../WorkersCompensation/PhysicianInformation";
import * as FileAttachments from "../WorkersCompensation/FileAttachments";
import * as Notifications from "../WorkersCompensation/Notifications";
import {
  FormFieldConfigType,
  FormFieldStateType,
  FormStateType,
  INPUT_TYPES,
} from "src/forms/types";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";

import { RootState } from "src/redux/store";
import {
  ASSOCIATE_INFORMATION_CONSTANTS,
  FILE_ATTACHMENTS_CONSTANTS,
  INCIDENT_INFORMATION_CONSTANTS,
  INCIDENT_TIME_AND_PLACE_CONSTANTS,
  PHYSICIAN_INFORMATION_CONSTANTS,
} from "src/forms/constants";
import { cloneDeep, isEqual } from "lodash";
import { setFormsStateFirstLoad } from "./reviewPageSlice";
import FormContent from "src/forms/FormContent";
import { STEP_NAMES } from "src/app/constants";
import { convertBytesTo } from "src/forms/FileUpload/FileUploadUtilities";
import { FileSize } from "src/forms/FileUpload/types";

interface ReviewPageProps {
  allFormConfigs: { [key: string]: FormFieldConfigType[] };
}
const ReviewPage = ({ allFormConfigs }: ReviewPageProps) => {
  const dispatch = useAppDispatch();
  //Redux Forms state
  const { allFormStates } = useAppSelector((state: RootState) => state.forms);
  const { formsStateFirstLoad, formsStateSaved } = useAppSelector(
    (state: RootState) => state.reviewPage
  );
  const setActiveStepIndex = (step: number) => {
    dispatch(setSkipToStep({ value: step - 1 }));
  };
  useLayoutEffect(() => {
    if (!formsStateSaved) {
      dispatch(setFormsStateFirstLoad({ value: cloneDeep(allFormStates) }));
    }
  }, []);

  const shouldSkipField = (
    fieldConfig: FormFieldConfigType,
    fieldState: FormFieldStateType,
    options?: { startAtField?: string; stopBeforeField?: string }
  ) => {
    const skipTypes = [INPUT_TYPES.BUTTON, INPUT_TYPES.FIELD_GROUP];
    if (
      options?.startAtField &&
      !(
        fieldConfig.uniqueName === options?.startAtField ||
        fieldConfig.name == options?.startAtField
      )
    ) {
      return true;
    }
    delete options?.startAtField;
    if (fieldState.hidden || skipTypes.includes(fieldConfig.type)) {
      return true;
    }
    return false;
  };

  const handleSpecialFieldTypes = (
    fieldConfig: FormFieldConfigType,
    value: any
  ) => {
    if (fieldConfig.type === INPUT_TYPES.SELECT) {
      value = value.label;
    }
    if (fieldConfig.type === INPUT_TYPES.MULTISELECT) {
      value = value.map((entry: { value: string; label: string }) => {
        return (
          <span key={entry.label}>
            {entry.label}
            <br></br>
          </span>
        );
      });
    }

    if (fieldConfig.type === INPUT_TYPES.UPLOAD) {
      value = value?.length ? (
        renderFiles(value)
      ) : (
        <Box fontSize="body-m" color="text-status-warning">
          No Files uploaded
        </Box>
      );
      return (
        <React.Fragment>
          {value}
          <br />
        </React.Fragment>
      );
    }
    if (["true", true].includes(value)) {
      value = "Yes";
    }
    if (["false", false].includes(value)) {
      value = "No";
    }

    return value;
  };

  const renderFields = (
    fieldsToRender: FormFieldConfigType[],
    formState: FormStateType,
    formName: string,
    options?: { startAtField?: string; stopBeforeField?: string }
  ) => {
    const renderedFields: any[] = [];
    let labelClass = "review-page-regular-label";
    let editedLabel = "";

    const initialFormState = formsStateFirstLoad?.[formName];
    for (const fieldConfig of fieldsToRender) {
      if (
        options?.stopBeforeField &&
        (fieldConfig.uniqueName === options?.stopBeforeField ||
          fieldConfig.name === options?.stopBeforeField)
      ) {
        return renderedFields;
      }
      const fieldState = formState[fieldConfig.uniqueName || fieldConfig.name];
      if (shouldSkipField(fieldConfig, fieldState, options)) {
        continue;
      }
      const initialFieldState =
        initialFormState?.[fieldConfig.uniqueName || fieldConfig.name];
      let { value } = fieldState;

      value = handleSpecialFieldTypes(fieldConfig, value);

      if (
        initialFieldState &&
        !isEqual(initialFieldState.value, fieldState.value)
      ) {
        labelClass = "review-page-highlighted-label";
        editedLabel = "(edited)";
      }

      renderedFields.push(
        <div key={fieldConfig.label}>
          <Box>
            <b
              className={labelClass}
            >{`${fieldConfig.label} ${editedLabel}`}</b>
          </Box>
          <div className="review-page-field-value">{value}</div>
        </div>
      );
      editedLabel = "";
      labelClass = "review-page-regular-label";
    }
    return renderedFields;
  };
  const renderFieldGroup = (
    label: string,
    fieldsToRender: FormFieldConfigType[],
    formState: FormStateType,
    formName: string,
    options?: {
      expandable?: boolean;
      hidden?: boolean;
      startAtField?: string;
      stopBeforeField?: string;
      columns?: number;
    }
  ) => {
    if (options?.hidden) {
      return null;
    }
    if (options?.expandable) {
      return (
        <React.Fragment>
          <ExpandableSection
            headerText={label}
            variant="default"
            defaultExpanded={true}
          >
            <ColumnLayout columns={options?.columns || 2} variant="text-grid">
              {renderFields(fieldsToRender, formState, formName, {
                startAtField: options?.startAtField,
                stopBeforeField: options?.stopBeforeField,
              })}
            </ColumnLayout>
          </ExpandableSection>
        </React.Fragment>
      );
    } else {
      return (
        <ColumnLayout columns={options?.columns || 2} variant="text-grid">
          {renderFields(fieldsToRender, formState, formName, {
            startAtField: options?.startAtField,
            stopBeforeField: options?.stopBeforeField,
          })}
        </ColumnLayout>
      );
    }
  };
  const renderFiles = (files: any[]) => {
    const result = [];
    for (const file of files) {
      result.push(
        <ColumnLayout columns={2} variant="text-grid" key={file.name}>
          <div>
            <Box variant="awsui-key-label">File Name</Box>
            <div>{file.name}</div>
          </div>

          <div>
            <Box variant="awsui-key-label">Size</Box>
            <div>{`${convertBytesTo(file.size, FileSize.MB).toFixed(2)} ${
              FileSize.MB
            }`}</div>
          </div>
        </ColumnLayout>
      );
    }
    return result;
  };
  const renderAllFileLists = (
    formState: FormStateType,
    fieldConfigs?: FormFieldConfigType[]
  ) => {
    if (!fieldConfigs?.length) {
      return null;
    }
    const fileUploadFieldConfigs = fieldConfigs.filter((field) => {
      return field.type === INPUT_TYPES.UPLOAD;
    });
    return renderFields(
      fileUploadFieldConfigs,
      formState,
      FILE_ATTACHMENTS_CONSTANTS.FORM_NAME
    );
  };
  const physicianInformation_0 = allFormConfigs[
    PhysicianInformation.formName
  ][0].fields!.find((f: FormFieldConfigType) => {
    return f.name === PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_0;
  })!;
  const physicianInformation_1 = allFormConfigs[
    PhysicianInformation.formName
  ][0].fields!.find((f: FormFieldConfigType) => {
    return f.name === PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_1;
  })!;
  const hospitalInformation = allFormConfigs[
    PhysicianInformation.formName
  ][0].fields!.find((f: FormFieldConfigType) => {
    return f.name === PHYSICIAN_INFORMATION_CONSTANTS.HOSPITAL_INFORMATION;
  })!;

  const witnessInformation_0 = allFormConfigs[
    IncidentInformation.formName
  ][0].fields!.find((f: FormFieldConfigType) => {
    return f.name === INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_0;
  })!;
  const witnessInformation_1 = allFormConfigs[
    IncidentInformation.formName
  ][0].fields!.find((f: FormFieldConfigType) => {
    return f.name === INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_1;
  })!;

  const fileAttachmentsState =
    allFormStates[FILE_ATTACHMENTS_CONSTANTS.FORM_NAME];

  const fileAttachmentsFields =
    allFormConfigs[FILE_ATTACHMENTS_CONSTANTS.FORM_NAME][0].fields;
  return (
    <Box margin={{ bottom: "l" }}>
      <SpaceBetween size="xxl">
        <SpaceBetween size="xs">
          <Header
            variant="h3"
            headingTagOverride="h2"
            actions={
              <Button
                onClick={() =>
                  setActiveStepIndex(
                    STEP_NAMES.indexOf(
                      ASSOCIATE_INFORMATION_CONSTANTS.FORM_NAME
                    ) + 1
                  )
                }
                ariaLabel="Edit, press this button to go back to Step 1: Associate Information"
              >
                Edit
              </Button>
            }
          >
            {`Step ${
              STEP_NAMES.indexOf(ASSOCIATE_INFORMATION_CONSTANTS.FORM_NAME) + 1
            }`}
          </Header>
          <Container
            header={
              <Header variant="h2" headingTagOverride="h3">
                {AssociateInformation.formHeader}
              </Header>
            }
          >
            <ColumnLayout columns={2} variant="text-grid">
              {renderFields(
                allFormConfigs[AssociateInformation.formName][0].fields!,
                allFormStates[AssociateInformation.formName],
                AssociateInformation.formName,
                {
                  startAtField:
                    ASSOCIATE_INFORMATION_CONSTANTS.ASSOCIATE_EMPLOYEE_ID,
                }
              )}
            </ColumnLayout>
          </Container>
        </SpaceBetween>

        <SpaceBetween size="xs">
          <Header
            variant="h3"
            headingTagOverride="h2"
            actions={
              <Button
                onClick={() =>
                  setActiveStepIndex(
                    STEP_NAMES.indexOf(
                      INCIDENT_TIME_AND_PLACE_CONSTANTS.FORM_NAME
                    ) + 1
                  )
                }
                ariaLabel="Edit, press this button to go back to Step 2: Incident Time and Place"
              >
                Edit
              </Button>
            }
          >
            {`Step ${
              STEP_NAMES.indexOf(INCIDENT_TIME_AND_PLACE_CONSTANTS.FORM_NAME) +
              1
            }`}
          </Header>

          <SpaceBetween size="l">
            <Container
              header={
                <Header variant="h2" headingTagOverride="h3">
                  {IncidentTimeAndPlace.formHeader}
                </Header>
              }
            >
              <SpaceBetween size="m">
                {renderFields(
                  allFormConfigs[IncidentTimeAndPlace.formName][0].fields!,
                  allFormStates[IncidentTimeAndPlace.formName],
                  IncidentTimeAndPlace.formName,
                  {
                    stopBeforeField:
                      INCIDENT_TIME_AND_PLACE_CONSTANTS.INCIDENT_DATE,
                  }
                )}
                <ColumnLayout columns={2} variant="text-grid">
                  {renderFields(
                    allFormConfigs[IncidentTimeAndPlace.formName][0].fields!,
                    allFormStates[IncidentTimeAndPlace.formName],
                    IncidentTimeAndPlace.formName,
                    {
                      startAtField:
                        INCIDENT_TIME_AND_PLACE_CONSTANTS.INCIDENT_DATE,
                    }
                  )}
                </ColumnLayout>
              </SpaceBetween>
            </Container>
          </SpaceBetween>
        </SpaceBetween>
        <SpaceBetween size="xs">
          <Header
            variant="h3"
            headingTagOverride="h2"
            actions={
              <Button
                onClick={() =>
                  setActiveStepIndex(
                    STEP_NAMES.indexOf(
                      INCIDENT_INFORMATION_CONSTANTS.FORM_NAME
                    ) + 1
                  )
                }
                ariaLabel="Edit, press this button to go back to Step 3: Incident Details"
              >
                Edit
              </Button>
            }
          >
            {`Step ${
              STEP_NAMES.indexOf(INCIDENT_INFORMATION_CONSTANTS.FORM_NAME) + 1
            }`}
          </Header>

          <Container
            header={
              <Header variant="h2" headingTagOverride="h3">
                {IncidentInformation.formHeader}
              </Header>
            }
          >
            {renderFieldGroup(
              "Primary Body Part Information",
              allFormConfigs[IncidentInformation.formName][0].fields![0]
                .fields!,
              allFormStates[IncidentInformation.formName],
              IncidentInformation.formName,
              {
                expandable: true,
                hidden:
                  allFormStates[IncidentInformation.formName][
                    INCIDENT_INFORMATION_CONSTANTS.BODY_PART_INFORMATION_0
                  ].hidden,
              }
            )}
            {renderFieldGroup(
              "Secondary Body Part Information",
              allFormConfigs[IncidentInformation.formName][0].fields![1]
                .fields!,
              allFormStates[IncidentInformation.formName],
              IncidentInformation.formName,
              {
                expandable: true,
                hidden:
                  allFormStates[IncidentInformation.formName][
                    INCIDENT_INFORMATION_CONSTANTS.BODY_PART_INFORMATION_1
                  ].hidden,
              }
            )}
            {renderFieldGroup(
              "Injury/Incident Details",
              allFormConfigs[IncidentInformation.formName][0].fields!,
              allFormStates[IncidentInformation.formName],
              IncidentInformation.formName,
              {
                expandable: true,
                startAtField: INCIDENT_INFORMATION_CONSTANTS.CAUSE_OF_INJURY,
                stopBeforeField:
                  INCIDENT_INFORMATION_CONSTANTS.QUESTIONABLE_CLAIM,
                columns: 1,
              }
            )}
            <br />
            <ExpandableSection
              headerText={"Concerns"}
              variant="default"
              defaultExpanded={true}
            >
              <ColumnLayout columns={1} variant="text-grid">
                {renderFields(
                  allFormConfigs[IncidentInformation.formName][0].fields!,
                  allFormStates[IncidentInformation.formName],
                  IncidentInformation.formName,
                  {
                    startAtField:
                      INCIDENT_INFORMATION_CONSTANTS.QUESTIONABLE_CLAIM,
                    stopBeforeField:
                      INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_AVAILABLE,
                  }
                )}
              </ColumnLayout>
              {renderFieldGroup(
                "Concerns",
                allFormConfigs[IncidentInformation.formName][0].fields!.find(
                  (f: FormFieldConfigType) => {
                    return (
                      f.name ===
                      INCIDENT_INFORMATION_CONSTANTS.QUESTIONABLE_CLAIM_GROUP
                    );
                  }
                )!.fields!,
                allFormStates[IncidentInformation.formName],
                IncidentInformation.formName,
                {
                  expandable: false,
                  hidden:
                    allFormStates[IncidentInformation.formName][
                      INCIDENT_INFORMATION_CONSTANTS.QUESTIONABLE_CLAIM_GROUP
                    ].hidden,
                  columns: 1,
                }
              )}
            </ExpandableSection>
            <br />
            <ExpandableSection
              headerText={"Witness Information"}
              variant="default"
              defaultExpanded={true}
            >
              <ColumnLayout columns={1} variant="text-grid">
                {renderFields(
                  allFormConfigs[IncidentInformation.formName][0].fields!,
                  allFormStates[IncidentInformation.formName],
                  IncidentInformation.formName,
                  {
                    startAtField:
                      INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_AVAILABLE,
                    stopBeforeField:
                      INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_0,
                  }
                )}
              </ColumnLayout>
              {renderFieldGroup(
                "Witness 1",
                witnessInformation_0.fields!,
                allFormStates[IncidentInformation.formName],
                IncidentInformation.formName,
                {
                  expandable: true,
                  hidden:
                    allFormStates[IncidentInformation.formName][
                      INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_0
                    ].hidden,
                }
              )}
              {renderFieldGroup(
                "Witness 2",
                witnessInformation_1.fields!,
                allFormStates[IncidentInformation.formName],
                IncidentInformation.formName,
                {
                  expandable: true,
                  hidden:
                    allFormStates[IncidentInformation.formName][
                      INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_1
                    ].hidden,
                }
              )}
            </ExpandableSection>
          </Container>
        </SpaceBetween>

        <SpaceBetween size="xs">
          <Header
            variant="h3"
            headingTagOverride="h2"
            actions={
              <Button
                onClick={() =>
                  setActiveStepIndex(
                    STEP_NAMES.indexOf(
                      PHYSICIAN_INFORMATION_CONSTANTS.FORM_NAME
                    ) + 1
                  )
                }
                ariaLabel="Edit, press this button to go back to Step 4: Hospital/Medical Provider Information"
              >
                Edit
              </Button>
            }
          >
            {`Step ${
              STEP_NAMES.indexOf(PHYSICIAN_INFORMATION_CONSTANTS.FORM_NAME) + 1
            }`}
          </Header>
          <Container
            header={
              <Header variant="h2" headingTagOverride="h3">
                {PhysicianInformation.formHeader}
              </Header>
            }
          >
            <ColumnLayout columns={1} variant="text-grid">
              {renderFields(
                allFormConfigs[PhysicianInformation.formName][0].fields!,
                allFormStates[PhysicianInformation.formName],
                PhysicianInformation.formName,
                {
                  stopBeforeField:
                    PHYSICIAN_INFORMATION_CONSTANTS.HOSPITAL_INFORMATION,
                }
              )}
            </ColumnLayout>
            {renderFieldGroup(
              "Hospital Information",
              [
                ...hospitalInformation.fields!,
                ...hospitalInformation.fields!.find(
                  (f: FormFieldConfigType) => {
                    return f.name === "phoneWithExtension";
                  }
                )!.fields!,
              ],
              allFormStates[PhysicianInformation.formName],
              PhysicianInformation.formName,
              {
                expandable: true,
                hidden:
                  allFormStates[PhysicianInformation.formName][
                    PHYSICIAN_INFORMATION_CONSTANTS.HOSPITAL_INFORMATION
                  ].hidden,
              }
            )}
            <br />
            <ColumnLayout columns={1} variant="text-grid">
              {renderFields(
                allFormConfigs[PhysicianInformation.formName][0].fields!,
                allFormStates[PhysicianInformation.formName],
                PhysicianInformation.formName,
                {
                  startAtField:
                    PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_AVAILABLE,
                  stopBeforeField:
                    PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_0,
                }
              )}
            </ColumnLayout>
            {renderFieldGroup(
              "Physician Information 1",
              [
                ...physicianInformation_0.fields!,
                ...physicianInformation_0.fields!.find(
                  (f: FormFieldConfigType) => {
                    return f.name === "phoneWithExtension";
                  }
                )!.fields!,
              ],
              allFormStates[PhysicianInformation.formName],
              PhysicianInformation.formName,
              {
                expandable: true,
                hidden:
                  allFormStates[PhysicianInformation.formName][
                    PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_0
                  ].hidden,
              }
            )}
            {renderFieldGroup(
              "Physician Information 2",
              [
                ...physicianInformation_1.fields!,
                ...physicianInformation_1.fields!.find(
                  (f: FormFieldConfigType) => {
                    return f.name === "phoneWithExtension";
                  }
                )!.fields!,
              ],
              allFormStates[PhysicianInformation.formName],
              PhysicianInformation.formName,
              {
                expandable: true,
                hidden:
                  allFormStates[PhysicianInformation.formName][
                    PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_1
                  ].hidden,
              }
            )}
          </Container>
        </SpaceBetween>

        <SpaceBetween size="xs">
          <Header
            variant="h3"
            headingTagOverride="h2"
            actions={
              <Button
                onClick={() =>
                  setActiveStepIndex(
                    STEP_NAMES.indexOf(FILE_ATTACHMENTS_CONSTANTS.FORM_NAME) + 1
                  )
                }
                ariaLabel="Edit, press this button to go back to Step 5: File Attachments"
              >
                Edit
              </Button>
            }
          >
            {`Step ${
              STEP_NAMES.indexOf(FILE_ATTACHMENTS_CONSTANTS.FORM_NAME) + 1
            }`}
          </Header>
          <Container
            header={
              <Header variant="h2" headingTagOverride="h3">
                {FileAttachments.formHeader}
              </Header>
            }
          >
            {renderAllFileLists(fileAttachmentsState, fileAttachmentsFields)}
          </Container>
        </SpaceBetween>
        <Form>
          <FormContent
            formName={Notifications.formName}
            formConfig={Notifications.formConfig}
            formValidation={Notifications.formValidation}
            data-testid="notifications-form"
            key="notifications-form"
          />
        </Form>
      </SpaceBetween>
    </Box>
  );
};
export default ReviewPage;
